<template>
    <div id="competitionDetail">

        <div class="card p-3 bg-warning" v-if="selectedCompetition !== undefined && selectedCompetition !== null && selectedCompetition.stato === 2 && isUpdate === false">
            <span class="font-weight-bolder">{{$t('competitionDetail.modale.loadingDb.sottotitolo')}}</span>
        </div>

        <template v-if="isCompetitionWithoutAnswers">
            <div class="card p-3 bg-warning">
                <span class="font-weight-bolder">{{$t('competitionDetail.bancaDatiSenzaRisposta')}}</span>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-body">
                            <h3 class="card-title font-size-15 mt-0">{{$t('competitionDetail.risposteEsatteAssegnare')}}</h3>
                            <div class="row">
                                <div class="col-md-6">
                                    <button type="button" data-toggle="modal" data-target="#modalAssignRightAnswers" :disabled="metaData.n_domande_assegnate === undefined" class="btn btn-primary">{{$t('competitionDetail.risposteAssegnate')}}</button>
                                </div>
                                <div class="col-md-6 text-right my-auto h3">
                                    <div class="field-loader ml-auto" v-if="isMetaDataForAssignLoading"></div>
                                    <span v-else class="badge badge-secondary">{{metaData.n_domande_assegnate}}</span>
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-md-6">
                                    <button type="button" data-toggle="modal" data-target="#modalAnswersToBeAssign" :disabled="metaData.n_domande_senza_risposta_by_materia === undefined" class="btn btn-primary">{{$t('competitionDetail.risposteDaAssegnare')}}</button>
                                </div>
                                <div class="col-md-6 text-right my-auto h3">
                                    <div class="field-loader ml-auto" v-if="isMetaDataForAssignLoading"></div>
                                    <span v-else class="badge badge-secondary">{{computeAnswersToBeAssignOrAlreadyAssign('n_domande_senza_risposta_by_materia')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body" v-if="selectedCompetition !== null">
                        <h3 class="card-title font-size-15 mt-0">{{selectedCompetition.ente.ente}}</h3>
                        <p class="card-text">
                            {{selectedCompetition.concorso}}<br>
                            {{selectedCompetition.anno}}
                        </p>
                        <p class="card-text descrizione-competition" v-if="selectedCompetition.descrizione !== null">
                            {{selectedCompetition.descrizione}}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="additionalResource.show !== undefined && additionalResource.show">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="header-title mb-4">{{$t('competitionDetail.risorseAggiuntive')}}</h5>
                        <div class="row">
                            <div class="col-md-2"
                                 v-if="verifyResource(additionalResource.url_facebook) || verifyResource(additionalResource.url_telegram)">
                                <div class="media">
                                    <div class="media-body text-center cursorPointer" @click="goToStudyGroup(additionalResource)">
                                        <img class="" width="40%" src="../assets/images/new-image/team.png"
                                             data-holder-rendered="true">
                                        <h6 class="mt-2">{{ $t('competitionDetail.gruppoDiStudio') }}</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2" v-if="verifyResource(additionalResource.url_news)">
                                <div class="media">
                                    <div class="media-body text-center">
                                        <a :href="additionalResource.url_news" target="_blank" ref="noopener">
                                            <img class="" width="40%" src="../assets/images/new-image/speaker.png" data-holder-rendered="true">
                                            <h6 class="mt-2">{{$t('competitionDetail.tienimiAggiornato')}}</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2" v-if="verifyResource(additionalResource.url_corso_online)">
                                <div class="media">
                                    <div class="media-body text-center">
                                        <a :href="additionalResource.url_corso_online" target="_blank" ref="noopener">
                                            <img class="" width="40%" src="../assets/images/new-image/icon-corso-online.png" data-holder-rendered="true">
                                            <h6 class="mt-2">{{$t('competitionDetail.corsoOnline')}}</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2" v-if="verifyResource(additionalResource.url_corso_dal_vivo)">
                                <div class="media">
                                    <div class="media-body text-center">
                                        <a :href="additionalResource.url_corso_dal_vivo" target="_blank" ref="noopener">
                                            <img class="" width="40%" src="../assets/images/new-image/icon-corsi-aula.png" data-holder-rendered="true">
                                            <h6 class="mt-2">{{$t('competitionDetail.corsiAula')}}</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2" v-if="verifyResource(additionalResource.url_manuale)">
                                <div class="media">
                                    <div class="media-body text-center">
                                        <a :href="additionalResource.url_manuale" target="_blank" ref="noopener">
                                            <img class="" width="40%" src="../assets/images/new-image/icon-manuali-libri.png" data-holder-rendered="true">
                                            <h6 class="mt-2">{{$t('competitionDetail.manuali')}}</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <ul class="nav nav-tabs nav-justified nav-tabs-custom" role="tablist">
                    <li class="nav-item">
                        <a :class="[computeTab, 'nav-link text-left p-3']" data-toggle="tab" href="#bancadati" role="tab" aria-selected="true" @click="setCurrentSimulation(undefined)">
                            <i class="mdi mdi-format-list-bulleted-square mdi-24px align-middle mr-3"></i> {{$t('competitionDetail.esercitatiBancaDati')}}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="[((tabType === tabsEnums.PERSONALIZED_TRIAL && tabType === $route.query.type) ? 'active' : ''), 'nav-link text-left p-3']" data-toggle="tab" href="#personalizzata" role="tab" aria-selected="false" @click="setCurrentSimulation(undefined)">
                            <i class="mdi mdi-clipboard-list-outline mdi-24px align-middle mr-3"></i> {{$t('competitionDetail.effettuaProvaPersonalizzata')}}
                        </a>
                    </li>
                    <li class="nav-item" v-if="simulations.length > 0">
                        <a :class="[((tabType === tabsEnums.CHALLENGE && tabType === $route.query.type) ? 'active' : ''), 'nav-link text-left p-3']" data-toggle="tab" href="#sfida" role="tab" aria-selected="false">
                            <i class="mdi mdi-crown-outline mdi-24px align-middle mr-3"></i>
                            {{$t('competitionDetail.sfidaGliAltriCandidati')}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="tab-content">
            <!-- ### TAB Esercitazione Banca Dati -->
            <div :class="[computeTab, 'tab-pane']" id="bancadati" role="tabpanel">
                <div class="card">
                    <div class="card-body">
                        <h5 class="header-title mb-4 text-uppercase">
                            <i class="mdi mdi-format-list-bulleted-square font-size-24 mr-1 align-middle"></i> {{$t('competitionDetail.elencoMaterieDisponibili')}}
                        </h5>
                        <p>{{$t('competitionDetail.selezionaUnaPiùMateriePerEsercitarti')}}</p>
                        <div class="table-responsive font-size-14" v-if="subjects.length > 0">
                            <table class="table table-centered table-hover mb-0">
                                <thead class="thead-light">
                                    <tr class="font-weight-bold">
                                        <th scope="col" class="font-weight-bold" width="3%"></th>
                                        <th scope="col">{{$t('competitionDetail.materia')}}</th>
                                        <th scope="col" class="text-center w-25">{{$t('competitionDetail.totDomande')}}</th>
                                    </tr>
                                </thead>
                                <tbody class="record_table">
                                    <tr>
                                        <th scope="row" width="3%">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" id="selectAllMateria" @click="onAllCheckboxClicked($event)" v-model="isCheckboxSubjectEmpty">
                                                <label class="custom-control-label cursorPointer" for="selectAllMateria"></label>
                                            </div>
                                        </th>
                                        <th class="pl-0">
                                            {{$t('competitionDetail.tutte')}}
                                        </th>

                                        <td class="text-center w-25">
                                            <span class="font-size-20" style="color: #3051d3">{{totDomande}}</span><br>
                                            <span class="badge badge-success font-size-11" v-if="stateOfCompletion.tot !== undefined">{{parsePct(stateOfCompletion.tot)}}%</span>
                                        </td>
                                    </tr>
                                    <template v-for="(subject, index) in subjects">
                                        <tr v-if="metaData.n_domande_by_materia[subject.id_materia] !== undefined && metaData.n_domande_by_materia[subject.id_materia] > 0">
                                            <th scope="row" width="3%">
                                                <div class="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" :id="subject.id_materia" v-model="configuration.checkboxSubjects[subject.id_materia]">
                                                    <label class="custom-control-label cursorPointer" :for="subject.id_materia"></label>
                                                </div>
                                            </th>
                                            <th class="pl-0">
                                                {{subject.materia_uff}}
                                            </th>

                                            <td class="text-center w-25">
                                                <span class="font-size-20" style="color: #3051d3">{{metaData.n_domande_by_materia[subject.id_materia]}}</span><br>
                                                <span class="badge badge-success font-size-11" v-if="stateOfCompletion.by_materia !== undefined">{{parsePct(stateOfCompletion.by_materia[subject.id_materia])}}%</span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center h5" v-else>
                            {{$t('competitionDetail.nonSonoPresentiMaterie')}}
                        </div>
                    </div>
                </div>

                <div class="card" >
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-8"><h5 class="header-title mt-2">{{$t('competitionDetail.impostazioniConcorso')}}</h5></div>
                            <div class="col-sm-12 col-md-4 text-right">
                                <button class="btn btn-light btn-rounded dropdown-toggle btn-sm text-right" type="button" @click="computeCollapseFilterMessage()" data-toggle="collapse" data-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample">
                                    <i class="mdi mdi-settings-outline mr-1"></i> {{messageFilter}}
                                </button>
                            </div>
                        </div>
                        <div id="collapseExample" :class="['row mt-3 collapse', isInitialFilterDefault ? '' : 'show']">
                            <div class="col-md-3 col-sm-12">
                                <div class="mt-4 mt-sm-0">
                                    <h5 class="font-size-14 mb-3">{{$t('competitionDetail.ordinamentoDomande')}}</h5>
                                    <div class="custom-control custom-radio mb-2">
                                        <input type="radio" id="customRadio1" v-model="orderQuestions" class="custom-control-input" value="CASUALE">
                                        <label class="custom-control-label cursorPointer" for="customRadio1">{{$t('competitionDetail.casuale')}}</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-2">
                                        <input type="radio" id="customRadio2" v-model="orderQuestions" class="custom-control-input" value="ALFABETICO">
                                        <label class="custom-control-label cursorPointer" for="customRadio2">{{$t('competitionDetail.alfabetico')}}</label>
                                    </div>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="customRadio3" v-model="orderQuestions" class="custom-control-input" value="UFFICIALE">
                                        <label class="custom-control-label cursorPointer" for="customRadio3">{{$t('competitionDetail.ufficiale')}}</label>
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <h5 class="font-size-14 mb-3">{{$t('competitionDetail.ordinamentoRisposte')}}</h5>
                                    <div class="custom-control custom-radio mb-2">
                                        <input type="radio" id="customRadio11" v-model="orderAnswers" class="custom-control-input" value="CASUALE">
                                        <label class="custom-control-label cursorPointer" for="customRadio11">{{$t('competitionDetail.casuale')}}</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-2">
                                        <input type="radio" id="customRadio22" v-model="orderAnswers" class="custom-control-input" value="UFFICIALE">
                                        <label class="custom-control-label cursorPointer" for="customRadio22">{{$t('competitionDetail.ufficiale')}}</label>
                                    </div>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="customRadio33" v-model="orderAnswers" class="custom-control-input" value="ESATTE">
                                        <label class="custom-control-label cursorPointer" for="customRadio33">{{$t('competitionDetail.soloRisposteEsatte')}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-12 custom-pt-sm-3">
                                <h5 class="header-title">{{$t('competitionDetail.esercitatiParoleChiavi')}}</h5>
                                <form>
                                    <div class="form-group mb-4">
                                        <label for="selectize-tags">{{$t('competitionDetail.scriviLaParolaDaSelezionare')}}</label>
                                        <input type="text" id="selectize-tags" :value="keyWordsComputed">
                                    </div>
                                    <div class="custom-control custom-radio mb-2">
                                        <input type="radio" id="customRadio99" v-model="configuration.typeSearchWordKey" class="custom-control-input" value="ANY">
                                        <label class="custom-control-label cursorPointer" for="customRadio99">{{$t('competitionDetail.ancheSoloPerAlcuneParole')}}</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-2">
                                        <input type="radio" id="customRadio88" v-model="configuration.typeSearchWordKey" class="custom-control-input" value="ALL">
                                        <label class="custom-control-label cursorPointer" for="customRadio88">{{$t('competitionDetail.tutteLeParoleIndicate')}}</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-2 pl-0 pt-3">
                                        <label class="switch">
                                          <input type="checkbox" v-model="configuration.favorite">
                                          <span class="slider round"></span>
                                        </label>
                                        <span class="pl-2 pt-1">{{$t('competitionDetail.contrassegnate')}}</span>
                                    </div>
                                    <div class="custom-control custom-radio mb-2 pl-0 pt-3">
                                        <label class="switch">
                                            <input type="checkbox" v-model="configuration.commento">
                                            <span class="slider round"></span>
                                        </label>
                                        <span class="pl-2 pt-1">{{$t('competitionDetail.conSpiegazione')}}</span>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-4 col-sm-12 custom-pt-sm-3">
                                <h5 class="header-title">{{$t('competitionDetail.esercitatiInBaseAdUnBlocco')}}</h5>
                                <p>{{$t('competitionDetail.rangeDiDomande')}}</p>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="switch">
                                                <input type="checkbox" v-model="configuration.rangeEnabled">
                                                <span class="slider round"></span>
                                            </label>
                                            <span class="pl-2 pt-1">{{$t('competitionDetail.abilitaDisabilitaBloccoDomande')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div :class="[(configuration.rangeEnabled) ? '' : 'd-none', 'row']" id="rowRange">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                {{$t('competitionDetail.inizioBlocco')}}:
                                                <span v-if="!fieldLoader">{{rangeStopMax}}</span>
                                                <span v-else> - </span>
                                                {{$t('generale.domande')}}
                                            </label>
                                            <input data-toggle="touchspin" name="rangeStart" @blur="validateRangeStart()" @input="setRangeStart($event)" type="text" v-model="configuration.rangeStart">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                {{$t('competitionDetail.fineBlocco')}}:
                                                <span v-if="!fieldLoader">{{rangeStopMax}}</span>
                                                <span v-else> - </span>
                                                {{$t('generale.domande')}}
                                            </label>
                                            <input data-toggle="touchspin" name="rangeStop" @blur="validateRangeStop()" @input="setRangeStop($event)" type="text" v-model="configuration.rangeStop">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-8">
                                <h5 class="header-title mb-4">{{$t('competitionDetail.riepilogoMaterieSelezionate')}}</h5>
                                <p>{{$t('competitionDetail.esercitatiSuSingolePiùMaterie')}}</p>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="table-responsive">
                                    <table class="table table-centered table-hover mb-0">
                                        <tbody>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-selectAllMateria" v-model="summarySelectAllMateria" @click="onSelectAllLevelsClick($event)">
                                                        <label class="custom-control-label cursorPointer" for="summary-selectAllMateria"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('competitionDetail.tutte')}}
                                                </th>
                                                <td class="text-center">
                                                    <div class="field-loader mx-auto" v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.totDomande}}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-leSai" v-model="summary.leSai">
                                                        <label class="custom-control-label cursorPointer" for="summary-leSai"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('competitionDetail.leSai')}}
                                                </th>
                                                <td class="text-center">
                                                    <div v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.leSai}}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-ancoraUnPiccoloSforzo" v-model="summary.ancoraUnPiccoloSforzo">
                                                        <label class="custom-control-label cursorPointer" for="summary-ancoraUnPiccoloSforzo"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('competitionDetail.ancoraUnPiccoloSforzo')}}
                                                </th>
                                                <td class="text-center">
                                                    <div v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.ancoraUnPiccoloSforzo}}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-ripetile" v-model="summary.ripetile">
                                                        <label class="custom-control-label cursorPointer" for="summary-ripetile"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('competitionDetail.meglioRipetere')}}
                                                </th>
                                                <td class="text-center">
                                                    <div v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.ripetile}}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-nonLeSai" v-model="summary.nonLeSai">
                                                        <label class="custom-control-label cursorPointer" for="summary-nonLeSai"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('competitionDetail.nonLeSo')}}
                                                </th>
                                                <td class="text-center">
                                                    <div v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.nonLeSai}}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-nonRisposte" v-model="summary.nonRisposte">
                                                        <label class="custom-control-label cursorPointer" for="summary-nonRisposte"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('competitionDetail.nonRisposte')}}
                                                </th>
                                                <td class="text-center">
                                                    <div v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.nonRisposte}}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button type="button" data-toggle="modal" data-target="#delStatisticsModal" class="ml-2 mt-3 btn btn-danger btn-sm btn-rounded waves-effect waves-light">{{$t('competitionDetail.azzeraStatistiche')}}</button>
                                    <button type="button" data-toggle="modal" data-target="#modalSync" class="ml-2 mt-3 btn btn-primary btn-sm btn-rounded waves-effect waves-light">{{$t('competitionDetail.forzaSincronizzazione')}}</button>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="text-center">
                                    <div class="mb-2" v-if="activeFilters > 0">{{$t('competitionDetail.filtri')}}: {{activeFilters}} {{$t('competitionDetail.attivi')}}</div>
                                    <h2 class="mb-4">
                                        <div class="field-loader-sum mx-auto" v-if="fieldLoader"></div>
                                        <template v-else>
                                            <span class="font-weight-bold text-danger">{{summedQuestions}}</span>
                                            <br>
                                        </template>
                                        <span style="font-size:12px; font-weight:normal">{{$t('competitionDetail.totaleDomandeSelezionate')}}</span>
                                    </h2>
                                    <button id="avvia-test" type="button" @click="setupExercise(exerciseMode.ESEGUITA)" :class="[((fieldLoader || summedQuestions < 1) ? 'cursorNotAllowed' : ''), 'btn btn-rounded btn-lg btn-success mt-3 mb-3']" :disabled="fieldLoader || summedQuestions < 1">
                                        <i class="mdi mdi-play-circle-outline font-size-24 align-middle"></i> {{$t('competitionDetail.avvia')}}
                                    </button>
                                    <br>
                                    <div>
                                      <button type="button" :class="[((fieldLoader || summedQuestions < 1) ? 'cursorNotAllowed' : ''), 'btn btn-outline-dark waves-effect btn-sm btn-rounded']" @click="downloadQuestions()" :disabled="fieldLoader || summedQuestions < 1">
                                        <i class="mdi mdi-printer mr-1 align-middle"></i> {{$t('competitionDetail.stampaBancaDati')}}
                                      </button>
                                    </div>
                                    <button id="button-ascolto" type="button" @click="verifyIfQuizHasImage()" :class="[((fieldLoader || summedQuestions < 1) ? 'cursorNotAllowed' : ''), 'btn btn-outline btn-secondary btn-sm mt-3 mb-3 mr-5']" :disabled="fieldLoader || summedQuestions < 1">
                                        <i class="mdi mdi-headphones font-size-24 align-middle"></i> <span></span>
                                    </button>
                                    <button id="button-lettura" type="button" @click="setupExercise(exerciseMode.LETTA)" :class="[((fieldLoader || summedQuestions < 1) ? 'cursorNotAllowed' : ''), 'btn btn-outline btn-secondary btn-sm mt-3 mb-3']" :disabled="fieldLoader || summedQuestions < 1">
                                        <i class="mdi mdi-eye-outline font-size-24 align-middle"></i> <span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ### Fine -->

            <!-- ### TAB Esercitazione Personalizzata -->
            <div :class="[((tabType === tabsEnums.PERSONALIZED_TRIAL) ? 'active' : ''), 'tab-pane']" id="personalizzata" role="tabpanel">
                <div class="card">
                    <div class="card-body">
                        <h5 class="header-title mb-4"><i class="mdi mdi-format-list-bulleted-square font-size-24 mr-1 align-middle"></i> {{$t('competitionDetail.customTrial.elencoMaterieDisponibili')}}</h5>
                        <div class="row pb-2 align-items-center">
                            <div class="col-md-10">
                                <span>{{$t('competitionDetail.customTrial.selezionaMateriaNumeroQuiz')}}</span>
                            </div>
                            <div class="col-md-2 text-right">
                                <button class="btn btn-primary" @click="resetCustomTrial()"><i class="mdi mdi-refresh"></i> {{$t('competitionDetail.customTrial.reset')}}</button>
                            </div>
                        </div>
                        <div class="table-responsive font-size-14" v-if="customTrial.subjects.length > 0">
                            <table class="table table-centered mb-0">
                                <thead class="thead-light">
                                    <tr class="font-weight-bold">
                                        <th scope="col" class="font-weight-bold" width="3%"></th>
                                        <th scope="col">{{$t('competitionDetail.customTrial.materia')}}</th>
                                        <th scope="col" class="text-center w-25">{{$t('competitionDetail.customTrial.totDomande')}}</th>
                                    </tr>
                                </thead>
                                <tbody class="record_table">
                                    <template v-for="(subject, index) in customTrial.subjects">
                                        <tr v-if="metaData.n_domande_by_materia[subject.id_materia] !== undefined && metaData.n_domande_by_materia[subject.id_materia] > 0">
                                            <th scope="row" width="3%">
                                                <div class="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" :id="'custom-trial-checkbox-' + subject.id_materia" v-model="subject.isChecked">
                                                    <label class="custom-control-label cursorPointer" :for="'custom-trial-checkbox-' + subject.id_materia"></label>
                                                </div>
                                            </th>
                                            <th class="pl-0">
                                                {{subject.materia_uff}}
                                            </th>

                                            <td class="text-center w-25">
                                                <div class="py-1">
                                                    <input type="text" data-toggle="touchspin" @blur="setRangeCustomTrial($event, index)" @input="setRangeCustomTrial($event, index)" :id="'custom-trial-' + subject.id_materia" v-model="subject.selectedQuiz" :disabled="!subject.isChecked">
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr>
                                        <th scope="row" width="3%">

                                        </th>
                                        <th class="pl-0">
                                            {{$t('competitionDetail.customTrial.totaleQuizSelezionati')}}
                                        </th>
                                        <td class="text-center" width="30%">
                                            <h3 class="mb-0">{{totalSubjectQuizSelected}}</h3>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center h5" v-else>
                            {{$t('competitionDetail.nonSonoPresentiMaterie')}}
                        </div>

                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-5">
                                <h5 class="header-title">{{$t('competitionDetail.customTrial.selezionaDurataTest')}}</h5>
                                <div class="row">
                                    <div class="col-md-12">

                                        <div class="row" style="padding-top: 2rem;">
                                            <div class="col-md-3">
                                                <span>{{ $t('competitionDetail.customTrial.timepicker.ore') }}</span>
                                            </div>
                                            <div class="col-md-8">
                                                <vue-slider v-model="customTrial.duration.HH" :tooltip="'always'" :max="23"></vue-slider>
                                            </div>
                                        </div>
                                        <div class="row pt-6">
                                            <div class="col-md-3">
                                                <span>{{ $t('competitionDetail.customTrial.timepicker.minuti') }}</span>
                                            </div>
                                            <div class="col-md-8">
                                                <vue-slider v-model="customTrial.duration.mm" :tooltip="'always'" :max="59"></vue-slider>
                                            </div>
                                        </div>
                                        <div class="row pt-6">
                                            <div class="col-md-3">
                                                <span>{{ $t('competitionDetail.customTrial.timepicker.secondi') }}</span>
                                            </div>
                                            <div class="col-md-8">
                                                <vue-slider v-model="customTrial.duration.ss" :tooltip="'always'" :max="45" :interval="15"></vue-slider>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-7">
                                <h5 class="header-title">{{$t('competitionDetail.customTrial.selezionaPunteggioRisposte')}}</h5>

                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group mb-4 alert alert-danger">
                                            <label>{{$t('competitionDetail.customTrial.risposteErrate')}}</label>
                                            <input type="number" max="0" class="form-control" @focusout="((customTrial.scores.wrong === '' || customTrial.scores.wrong > '0') ? customTrial.scores.wrong = '-1' : false)" v-model="customTrial.scores.wrong">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group mb-4 alert alert-warning">
                                            <label>{{$t('competitionDetail.customTrial.nonRisposte')}}</label>
                                            <input type="number" class="form-control" @focusout="((customTrial.scores.noAnswers === '') ? customTrial.scores.noAnswers = '0' : false)" v-model="customTrial.scores.noAnswers">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group mb-4 alert alert-success">
                                            <label>{{$t('competitionDetail.customTrial.risposteEsatte')}}</label>
                                            <input type="number" min="0" class="form-control" @focusout="((customTrial.scores.right === '' || customTrial.scores.right < '0') ? customTrial.scores.right = '1' : false)" v-model="customTrial.scores.right">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row text-right">
                            <div class="col-12">
                                <button id="avvia-test-personalizzato" type="button" @click="setupPersonalizedTrial()" :class="[((verifyStartButtonCustomTrial()) ? 'cursorNotAllowed' : ''), 'btn btn-rounded btn-lg btn-success mt-3 mb-3']" :disabled="verifyStartButtonCustomTrial()">
                                    <i class="mdi mdi-play-circle-outline font-size-24 align-middle"></i> {{$t('competitionDetail.avvia')}}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- ### Fine -->

            <!-- ### TAB Simulazione -->
            <div :class="[((tabType === tabsEnums.CHALLENGE) ? 'active' : ''), 'tab-pane']" id="sfida" role="tabpanel" v-if="simulations.length > 0">
                <template v-if="simulations.length === 1 || indexSelectedSimulation !== undefined">
                    <div class="card" v-if="simulations[indexSelectedSimulation] !== undefined">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 text-center mb-5">
                                    <h5>{{$t('competitionDetail.simulations.punteggioAttuale')}}</h5>
                                    <p>{{$t('competitionDetail.simulations.calcolatoUltimeProve')}}</p>
                                    <h2><i class="mdi mdi-crown-outline font-size-24"></i></h2>
                                    <h1><strong>{{simulations[indexSelectedSimulation].punteggio_medio}}</strong></h1>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-2 offset-1 ">
                                    <div class="card noti border mt-4 mt-lg-0 mb-0">
                                        <div class="card-body">
                                            <div class="text-center">
                                                <i class="mdi mdi-clipboard-list-outline font-size-24"></i>
                                                <h6 class="alert-heading">{{$t('competitionDetail.simulations.domande')}}</h6>
                                                <p class="text-muted font-size-20">{{simulations[indexSelectedSimulation].numero_domande}}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 ">
                                    <div class="card noti border mt-4 mt-lg-0 mb-0">
                                        <div class="card-body">
                                            <div class="text-center">
                                                <i class="mdi mdi-timer font-size-24"></i>
                                                <h6 class="alert-heading">{{$t('competitionDetail.simulations.tempo')}}</h6>
                                                <p class="text-muted font-size-20">{{simulations[indexSelectedSimulation].durata}}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="card noti border mt-4 mt-lg-0 mb-0">
                                        <div class="card-body">
                                            <div class="text-center">
                                                <i class="mdi mdi-file-document-box-check-outline font-size-24"></i>
                                                <h6 class="alert-heading">{{$t('competitionDetail.simulations.rispEsatte')}}</h6>
                                                <p class="text-success font-size-20">{{simulations[indexSelectedSimulation].punteggio_risposte_corrette}}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="card border mb-0">
                                        <div class="card-body">
                                            <div class="text-center">
                                                <i class="mdi mdi-cancel font-size-24"></i>
                                                <h6 class="alert-heading">{{$t('competitionDetail.simulations.nonRisposte')}}</h6>
                                                <p class="text-muted font-size-20">{{simulations[indexSelectedSimulation].punteggio_non_risposte}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="card noti border mt-4 mt-lg-0 mb-0">
                                        <div class="card-body">
                                            <div class="text-center">
                                                <i class="mdi mdi-file-document-box-remove-outline font-size-24"></i>
                                                <h6 class="alert-heading">{{$t('competitionDetail.simulations.rispErrate')}}</h6>
                                                <p class="text-danger font-size-20">{{simulations[indexSelectedSimulation].punteggio_risposte_sbagliate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end row -->
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button @click="setupSimulation(simulations[indexSelectedSimulation])" class="btn btn-rounded btn-lg btn-success mt-3 mb-3"><strong style="color:white">{{$t('competitionDetail.avvia')}}</strong></button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row" v-if="simulations[indexSelectedSimulation]">
                                <div class="col-5">
                                    <h5>{{$t('competitionDetail.simulations.infoGraduatoria')}}</h5>
                                    <div class="table-responsive font-size-12">
                                        <template v-if="simulations[indexSelectedSimulation].posizione_classifica !== undefined && simulations[indexSelectedSimulation].posizione_classifica > 0">
                                            <div>
                                                <span>{{$t('competitionDetail.simulations.attualePosizioneClassifica')}} {{simulations[indexSelectedSimulation].posizione_classifica}}</span>
                                            </div>
                                            <div v-if="simulations[indexSelectedSimulation].numero_partecipanti_punteggio_superiore >= 0">
                                                <span>{{$t('competitionDetail.simulations.su')}} {{simulations[indexSelectedSimulation].numero_partecipanti}} {{$t('competitionDetail.simulations.partecipanti')}} {{simulations[indexSelectedSimulation].numero_partecipanti_punteggio_superiore}} {{$t('competitionDetail.simulations.numeroPartecipantiPunteggioSuperiore')}}</span>
                                            </div>
                                        </template>
                                        <div v-else>
                                            <div>
                                                <span>{{$t('competitionDetail.simulations.nonPresenteGraduatoria')}}</span>
                                            </div>

                                            <div v-if="simulations[indexSelectedSimulation].numero_partecipanti >= 0">
                                                <span>{{$t('competitionDetail.simulations.numeroDiCandidatiPartecipanti')}} {{simulations[indexSelectedSimulation].numero_partecipanti}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <h5>{{$t('competitionDetail.simulations.ultimeCinqueProve')}}</h5>
                                    <div class="table-responsive font-size-12">
                                        <table class="table table-centered mb-0" v-if="simulations[indexSelectedSimulation].simResults !== undefined && simulations[indexSelectedSimulation].simResults.length > 0">
                                            <thead class="thead-light">
                                            <tr class="font-weight-bold">
                                                <th scope="col">{{$t('competitionDetail.simulations.dataTempo')}}</th>
                                                <th scope="col" class="text-center">{{$t('competitionDetail.simulations.corrette')}}</th>
                                                <th scope="col" class="text-center">{{$t('competitionDetail.simulations.sbagliate')}}</th>
                                                <th scope="col" class="text-center">{{$t('competitionDetail.simulations.ultimeProveNonRisposte')}}</th>
                                                <th scope="col" class="text-center"> </th>
                                            </tr>
                                            </thead>
                                            <tbody class="record_table">
                                                <template v-for="(simulation, index) in simulations[indexSelectedSimulation].simResults">
                                                    <tr>
                                                        <th>
                                                            {{ formatData(simulation.data_simulazione) }}
                                                        </th>
                                                        <td class="text-center" >
                                                            {{ simulation.numero_risposte_corrette }}
                                                        </td>
                                                        <td class="text-center" >
                                                            {{ simulation.numero_risposte_errate }}
                                                        </td>
                                                        <td class="text-center" >
                                                            {{ computeNoAnswers(simulation) }}
                                                        </td>
                                                        <td class="text-center" >
                                                            <span class="btn btn-rounded btn-sm btn-primary" v-if="index === 0" @click="rewatchLastSim(simulations[indexSelectedSimulation])">{{$t('competitionDetail.simulations.rivedi')}}</span>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                        <div v-else>
                                            <span>{{$t('competitionDetail.simulations.nonHaiProve')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="simulations.length >= 2 && indexSelectedSimulation === undefined">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <img class="w-25" src="../assets/images/more_sim.png">
                            </div>

                            <div class="table-responsive font-size-14">
                                <table class="table table-centered table-hover mb-0">
                                    <tbody class="record_table">
                                    <template v-for="(simulation, index) in simulations">
                                        <tr class="cursorPointer" @click="getSimulationDetail(index)">
                                            <td style="width: 5%;">
                                                <i class="mdi mdi-account-supervisor text-primary" style="font-size: 30px;"></i>
                                            </td>
                                            <td class="w-100 p-3">
                                                <div class="w-100">
                                                    <h5 class="font-weight-bolder text-success">{{$t('generale.apri')}}</h5>
                                                </div>
                                                <div class="w-100">
                                                    <span>{{simulation.concorso}} - {{simulation.descrizione}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <!-- ### Fine -->

        </div>

        <!-- Modal delete stats -->
        <div class="modal fade" id="delStatisticsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">{{$t('competitionDetail.modale.tabellaMaterie.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <template v-if="!isCompetitionWithoutAnswers">
                            <!-- Blocco scelta azzera in remoto/locale
                            <div class="d-inline">
                                <label class="switch">
                                    <input type="checkbox" v-model="modalStatsRemote">
                                    <span class="slider round slider-stats"></span>
                                </label>
                            </div>
                            <div class="d-inline ml-2">
                                <span v-if="modalStatsRemote">{{$t('competitionDetail.modale.tabellaMaterie.azzeraAncheInRemoto')}}</span>
                                <span v-else>{{$t('competitionDetail.modale.tabellaMaterie.azzeraSoloInLocale')}}</span>
                            </div>
                            -->
                        </template>
                        <template v-else>
                            <div>
                                <span>{{$t('competitionDetail.modale.tabellaMaterie.attenzioneAzzeramentoRisposte')}}</span>
                            </div>
                        </template>
                        <div class="table-responsive font-size-14">
                            <table class="table table-centered table-hover mb-0">
                                <thead class="thead-light">
                                <tr class="font-weight-bold">
                                    <th scope="col" class="font-weight-bold" width="3%">
                                    </th>
                                    <th scope="col" class="pl-0">{{$t('competitionDetail.materia')}}</th>
                                </tr>
                                </thead>
                                <tbody class="record_table">
                                    <tr>
                                        <th scope="row" width="3%">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" id="modalSelectAllMateria" v-model="modalSelectAllMateria">
                                                <label class="custom-control-label cursorPointer" for="modalSelectAllMateria"></label>
                                            </div>
                                        </th>
                                        <th class="pl-0">
                                            {{$t('competitionDetail.tutte')}}
                                        </th>
                                    </tr>
                                    <template v-for="(subject, index) in subjects">
                                        <tr v-if="metaData.n_domande_by_materia[subject.id_materia] !== undefined && metaData.n_domande_by_materia[subject.id_materia] > 0">
                                            <th scope="row" width="3%">
                                                <div class="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" :id="'modal' + subject.id_materia" v-model="modalSubjects[subject.id_materia]">
                                                    <label class="custom-control-label cursorPointer" :for="'modal' + subject.id_materia"></label>
                                                </div>
                                            </th>
                                            <th class="pl-0">
                                                {{subject.materia_uff}}
                                            </th>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" :class="[(verifyIfNeedToDisableConfirmDelStatsButton() ? 'cursorNotAllowed' : ''), 'btn btn-primary']" @click="emptyStats()" :disabled="verifyIfNeedToDisableConfirmDelStatsButton()" data-dismiss="modal">{{$t('generale.conferma')}}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Modal delete stats -->

        <!-- Modal error -->
        <div id="modalErrorCompetitionDetail" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorLabel">{{$t('competitionDetail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-error" class="modal-body">
                        <p id="message-body-error-competition-detail"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal error -->


        <!-- Modal download questions -->
        <div id="dlModalQuestions" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dlModalQuestionsLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title mt-0" id="dlModalQuestionsLabel">{{$t('competitionDetail.modale.stampaDati.titolo')}}</h5>
              </div>
              <div id="modal-body" class="modal-body">
                <h5 class="font-size-16">{{$t('competitionDetail.modale.stampaDati.sottotitolo')}}</h5>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                <button type="button" class="btn btn-primary waves-effect waves-light" @click="$router.push({ name: routesNames.SUBSCRIPTION })" data-dismiss="modal">{{$t('generale.acquista')}}</button>
              </div>
            </div><!-- /.modal-content -->
          </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal download questions -->

        <!-- Modal error competition -->
        <div id="modalErrorCompetition" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorCompetitionLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorCompetitionLabel">{{$t('competitionDetail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-error-competition" class="modal-body">
                        <p id="message-body-competition"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="$router.push({ name: 'Home' })" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal error competition -->

        <!-- Modal sync -->
        <div id="modalSync" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalSyncLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalSyncLabel">{{$t('competitionDetail.modale.sync.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-sync" class="modal-body">
                        <p id="message-body-sync">{{$t('competitionDetail.modale.sync.sottotitolo')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" data-toggle="modal" data-target="#modalConfirmForceSyncFromWeb" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('competitionDetail.modale.sync.sincronizzaDalWeb')}}</button>
                        <button type="button" data-toggle="modal" data-target="#modalConfirmForceSyncToWeb" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('competitionDetail.modale.sync.sincronizzaVersoIlWeb')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal sync -->

        <!-- Modal download db -->
        <div id="modalDownloadDb" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalDownloadDbLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div id="modal-body-download" class="modal-body text-center">
                        <template v-if="downloadProgressValue <= 99">
                            <h5 class="modal-title mt-0">{{$t('competitionDetail.modale.downloadDb.downloadDati')}}</h5>
                        </template>
                        <template v-else>
                            <h5 class="modal-title mt-0">{{$t('competitionDetail.modale.downloadDb.salvataggioDati')}}</h5>
                        </template>
                        <div class="pt-2">
                            <div class="progress" style="height: 2rem!important;" v-if="downloadProgressValue <= 99">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="'width: ' + downloadProgressValue + '%'" :aria-valuenow="downloadProgressValue" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div v-else class="loader mx-auto"></div>
                        </div>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal download db -->

        <!-- Modal limit quiz -->
        <div id="modalLimitQuiz" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLimitQuizLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalLimitQuizLabel">{{$t('competitionDetail.modale.limitQuiz.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-limit" class="modal-body">
                        <p id="message-body-limit">{{$t('competitionDetail.modale.limitQuiz.sottotitolo')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.SUBSCRIPTION })">{{$t('competitionDetail.modale.limitQuiz.passaPremium')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal limit quiz -->

        <!-- Modal update db -->
        <div id="modalUpdateDb" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalUpdateDbLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalmodalUpdateDbLabel">{{$t('competitionDetail.modale.updateDb.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-update-db" class="modal-body">
                        <p id="message-body-update-db">{{$t('competitionDetail.modale.updateDb.sottotitolo')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" id="button-update-db" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.conferma')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal update db -->

        <!-- Modal quiz has images -->
        <div id="modalQuizHasImages" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalQuizHasImagesLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalQuizHasImagesLabel">{{$t('competitionDetail.modale.quizHasImage.titolo')}}</h5>
                    </div>
                    <div id="modal-body-quiz-has-image" class="modal-body">
                        <p id="message-body-quiz-has-image">{{$t('competitionDetail.modale.quizHasImage.alcuniQuizHannoImmagine')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" @click="setupExercise(exerciseMode.ASCOLTATA)" data-dismiss="modal">{{$t('generale.conferma')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal quiz has images -->

        <!-- Modal assign right answers -->
        <div id="modalAssignRightAnswers" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalAssignRightAnswersLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalAssignRightAnswersLabel">
                            <span v-if="metaData.n_domande_assegnate > 0">{{$t('competitionDetail.modale.selezionaMateriaAssegnazioneRisposte')}}</span>
                            <span v-else>{{$t('competitionDetail.modale.errore.titolo')}}</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-assign" class="modal-body">
                        <div v-if="metaData.n_domande_assegnate < 1">
                            {{$t('competitionDetail.modale.nonSonoPresentiDomandeDaAssegnare')}}
                        </div>
                        <div v-else class="table-responsive font-size-14">
                            <table class="table table-centered table-hover mb-0">
                                <thead class="thead-light">
                                <tr class="font-weight-bold">
                                    <th scope="col">{{$t('competitionDetail.materia')}}</th>
                                    <th scope="col" class="text-center w-25">{{$t('competitionDetail.totDomande')}}</th>
                                </tr>
                                </thead>
                                <tbody class="record_table">
                                <template v-for="(subject, index) in subjects">
                                    <tr @click="setupAssignAnswers(true, subject.id_materia)" class="cursorPointer" v-if="metaData.n_domande_assegnate_by_materia[subject.id_materia] !== undefined && metaData.n_domande_assegnate_by_materia[subject.id_materia] > 0">
                                        <th>
                                            {{subject.materia_uff}}
                                        </th>

                                        <td class="text-center w-25">
                                            <span class="font-size-20" style="color: #3051d3">{{metaData.n_domande_assegnate_by_materia[subject.id_materia]}}</span>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer" v-if="metaData.n_domande_assegnate < 1">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal assign right answers -->

        <!-- Modal answers to be assign -->
        <div id="modalAnswersToBeAssign" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalAnswersToBeAssignLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalAnswersToBeAssignLabel">{{$t('competitionDetail.modale.selezionaMateriaAssegnazioneRisposte')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-answers-assign" class="modal-body">
                        <div class="table-responsive font-size-14">
                            <table class="table table-centered table-hover mb-0">
                                <thead class="thead-light">
                                <tr class="font-weight-bold">
                                    <th scope="col">{{$t('competitionDetail.materia')}}</th>
                                    <th scope="col" class="text-center w-25">{{$t('competitionDetail.totDomande')}}</th>
                                </tr>
                                </thead>
                                <tbody class="record_table">
                                    <template v-for="(subject, index) in subjects">
                                        <tr @click="setupAssignAnswers(false, subject.id_materia)" class="cursorPointer" v-if="metaData.n_domande_senza_risposta_by_materia[subject.id_materia] !== undefined && metaData.n_domande_senza_risposta_by_materia[subject.id_materia] > 0">
                                            <th>
                                                {{subject.materia_uff}}
                                            </th>

                                            <td class="text-center w-25">
                                                <span class="font-size-20" style="color: #3051d3">{{computeQuestionsToBeAssignByMateria(subject)}}</span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal answers to be assign -->

        <!-- Modal force sync error -->
        <div id="modalErrorForceSync" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorForceSyncLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorForceSyncLabel">{{$t('competitionDetail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-error-force-sync" class="modal-body">
                        <p id="message-body-error-force-sync"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal force sync error error -->

        <!-- Modal confirm sync from web -->
        <div id="modalConfirmForceSyncFromWeb" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalConfirmForceSyncFromWebLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalConfirmForceSyncFromWebLabel">{{$t('competitionDetail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-confirm-force-sync-from" class="modal-body">
                        <p id="message-body-confirm-force-sync-from">{{$t('competitionDetail.modale.sync.confermaFromWeb')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" @click="forceSync('fromWeb')" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.conferma')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal confirm sync from web -->

        <!-- Modal confirm sync to web -->
        <div id="modalConfirmForceSyncToWeb" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalConfirmForceSyncToWebLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalConfirmForceSyncToWebLabel">{{$t('competitionDetail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-confirm-force-sync-to" class="modal-body">
                        <p id="message-body-confirm-force-sync-to">{{$t('competitionDetail.modale.sync.confermaToWeb')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" @click="forceSync('toWeb')" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.conferma')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal confirm sync to web -->

        <!-- Modal pdf image not found -->
        <div id="modalPdfImageNotFound" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalPdfImageNotFoundLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalPdfImageNotFoundLabel">{{$t('competitionDetail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-image-not-found" class="modal-body">
                        {{$t('competitionDetail.modale.errore.immaginiPdf')}}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal pdf image not found -->


        <!-- Modal study group -->
        <div id="modalStudyGroup" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLimitQuizLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalLimitQuizLabel">{{$t('competitionDetail.modale.gruppiStudio.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-limit" class="modal-body">
                        <p id="message-body-limit">{{$t('competitionDetail.modale.gruppiStudio.sottotitolo')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="goTo(additionalResource.url_facebook)">{{$t('generale.facebook')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="goTo(additionalResource.url_telegram)">{{$t('generale.telegram')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal study group -->

    </div>
</template>

<script>
    import {MODULES} from "@/store/modules-enum";
    import {COMPETITION_GETTERS} from "@/store/competition/getters-enum";
    import {mapActions, mapGetters} from 'vuex'
    import $ from 'jquery'
    import 'bootstrap-touchspin'
    import 'selectize'
    import {COMPETITION_ACTIONS} from "@/store/competition/actions-enum";
    import StatsManager from '../lib/ConcorsandoWasm/StatsManager'
    import {LivelliConoscenzaEnum} from "@/lib/ConcorsandoWasm/Constants";
    import Worker from "../lib/ConcorsandoWasm/WrappedWorker";
    import _ from 'lodash'
    import moment from 'moment';
    import {COMPETITION_MUTATIONS} from "@/store/competition/mutations-enum";
    import {AUTH_GETTERS} from "@/store/auth/getters-enum";
    import ImagesClient from "@/lib/ConcorsandoWasm/ImagesClient";
    import Swal from "sweetalert2";
    import { competitionDetailTabsEnums } from "@/helpers/enums";
    import { QuizOrderEnum, AnswerOrderEnum, SearchModeEnum } from "@/lib/ConcorsandoWasm/Constants";
    import EsercitazioneManager from "@/lib/ConcorsandoWasm/EsercitazioneManager";
    import {PROFILE_ACTIONS} from "@/store/profile/actions-enum";
    import {ModalitaEsercitazioneEnum} from "@/lib/ConcorsandoWasm/Constants";
    import VueSlider from 'vue-slider-component'
    import {routesEnums} from "@/helpers/enums";
    import {getNumberBetween, validateImage} from "@/lib/utils";
    import firebase from "firebase";

    const worker = Worker.instance
    const statsManager = StatsManager.instance
    const imagesClient = ImagesClient.instance
    const esercitazioneManager = EsercitazioneManager.instance

    let performSearchTimeout = null
    let lastSearchNonce = null

    const imagesCache = {
        url: undefined,
        promise: undefined,
    };

    export default {
        name: "CompetitionDetail",
        props: ['idCompetition', 'tab'],
        components: {
            VueSlider
        },
        data () {
            return {
                isMetaDataForAssignLoading: true,
                greetingSpeech: new window.SpeechSynthesisUtterance(),
                routesNames: routesEnums,
                tabType: this.tab,
                isLoading: true,
                exerciseMode: ModalitaEsercitazioneEnum,
                isUpdate: false,
                downloadProgressValue: 0,
                messageFilter: this.$t('competitionDetail.apriImpostazioni'),
                tabsEnums: competitionDetailTabsEnums,
                orderQuestions: QuizOrderEnum.ALFABETICO,
                orderAnswers: AnswerOrderEnum.CASUALE,
                configuration: {
                    checkboxSubjects: {},
                    keyWords: [],
                    typeSearchWordKey: SearchModeEnum.ANY,
                    favorite: false,
                    commento: false,
                    rangeEnabled: false,
                    rangeStart: 1,
                    rangeStop: 1,
                },
                metaConfig: {
                    isRangeStopCustom: false,
                },
                isInitialFilterDefault: true,
                filtersReady: false,
                savedConfiguration: {},
                fieldLoader: false,
                selectedCompetition: null,
                modalSelectAllMateria: true,
                modalSubjects: {},
                totDomande: 0,
                questions: 0,
                summarySelectAllMateria: false,
                summary: {
                    ancoraUnPiccoloSforzo: false,
                    nonRisposte: false,
                    nonLeSai: false,
                    ripetile: false,
                    leSai: false
                },
                additionalResource: {},
                currentStats: {},
                metaData: [],
                subjects: [],
                countRequests: 0,
                stateOfCompletion: {
                    by_materia: [],
                    tot: 0
                },
                modalStatsRemote: true,
                quizHasImage: false,
                isCompetitionWithoutAnswers: false,
                /**
                 * Custom trial
                 */
                customTrial: {
                    subjects: [],
                    duration: {
                        HH: 1,
                        mm: 0,
                        ss: 0
                    },
                    scores: {
                        right: "1",
                        wrong: "-1",
                        noAnswers: "0"
                    }
                },
                resetPending: false,
                /**
                 * Simulations
                 */
                indexSelectedSimulation: undefined,
                simulations: []
            }
        },
        beforeDestroy() {
            imagesClient.clear();
            imagesCache.url = undefined;
            imagesCache.promise = undefined;
        },
        computed: {
            ...mapGetters(MODULES.COMPETITION, [COMPETITION_GETTERS.SELECTED_COMPETITIONS, COMPETITION_GETTERS.COMPETITIONS]),
            ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.PROFILE]),
            totalSubjectQuizSelected () {
                let totQuiz = 0
                this.customTrial.subjects.forEach(sub => {
                    if (sub.selectedQuiz !== undefined && sub.isChecked && this.metaData.n_domande_by_materia[sub.id_materia] !== undefined && this.metaData.n_domande_by_materia[sub.id_materia] > 0)
                        totQuiz += sub.selectedQuiz
                })
                return totQuiz
            },
            computeTab() {
                if (this.tabType !== this.$route.query.type && this.$route.query.type === this.tabsEnums.EXERCISE) {
                    return 'active'
                } else {
                    if (this.tabType === this.tabsEnums.EXERCISE
                        || (this.tabType !== this.tabsEnums.CHALLENGE
                            && this.tabType !== this.tabsEnums.PERSONALIZED_TRIAL)
                        || this.tabType === undefined) {
                        return 'active'
                    }
                }
            },
            keyWordsComputed: function () {
                const keyWords = _.cloneDeep(this.configuration.keyWords)
                const keyWordsString = keyWords.toString()
                return keyWordsString.replace(/,/g, " ")
            },
            rangeStopMax: function () {
                const context = this
                let rangeStopMax = 0
                if (context.isCheckboxSubjectEmpty) {
                    rangeStopMax = this.totDomande
                } else {
                    Object.keys(this.configuration.checkboxSubjects).forEach(idSubject => {
                        if (context.configuration.checkboxSubjects[idSubject]) {
                            rangeStopMax += (context.metaData.n_domande_by_materia !== undefined) ? context.metaData.n_domande_by_materia[idSubject] : 0
                        }
                    })
                }
                return rangeStopMax
            },
            isCheckboxSubjectEmpty: {
                get() {
                    if (!this.isLoading) {
                        const firstTrueCheckbox = _.find(Object.values(this.configuration.checkboxSubjects), (s) => s === true)
                        return firstTrueCheckbox === undefined
                    } else {
                        return false
                    }
                },
                set() {
                    this.configuration.checkboxSubjects = {}
                }
            },
            summedQuestions: function () {
                if (this.summarySelectAllMateria) {
                    return this.currentStats.totDomande
                } else {
                    let questions = ((this.summary.ancoraUnPiccoloSforzo) ? this.currentStats.ancoraUnPiccoloSforzo : 0)
                    questions += ((this.summary.nonRisposte) ? this.currentStats.nonRisposte : 0)
                    questions += ((this.summary.nonLeSai) ? this.currentStats.nonLeSai : 0)
                    questions += ((this.summary.ripetile) ? this.currentStats.ripetile : 0)
                    questions += ((this.summary.leSai) ? this.currentStats.leSai : 0)
                    return questions
                }
            },
            activeFilters: function () {
                let filtersActive = [false, false, false, false]
                filtersActive[0] = this.configuration.keyWords.length > 0 && this.configuration.keyWords[0] !== "";
                filtersActive[1] = this.configuration.rangeEnabled;
                filtersActive[2] = this.configuration.favorite;
                filtersActive[3] = this.configuration.commento;
                return _.filter(filtersActive, (fA) => fA).length
            }
        },
        watch: {
            rangeStopMax: function () {
                if (this.rangeStopMax < this.configuration.rangeStart) {
                    this.configuration.rangeStart = this.rangeStopMax
                }
                if (!this.metaConfig.isRangeStopCustom || this.rangeStopMax < this.configuration.rangeStop) {
                    // Se forzo il cambiamento di rangeStop, allora non è più da considerarsi un valore custom
                    this.metaConfig.isRangeStopCustom = false
                    this.configuration.rangeStop = this.rangeStopMax
                }
            },
            modalSelectAllMateria: function(newValue, oldValue) {
                if (newValue) {
                    this.modalSubjects = {}
                }
            },
            modalSubjects: {
                deep: true,
                handler (newValue, oldValue) {
                    if (!_.isEmpty(newValue)) {
                        this.modalSelectAllMateria = false
                    }
                }
            },
            summarySelectAllMateria: function(newValue, oldValue) {
                if (newValue) {
                    this.resetSummaryCheckbox()
                    this.commitCompetition()
                }
            },
            summary: {
                deep: true,
                handler (newValue, oldValue) {
                    if (_.includes(Object.values(newValue), true)) {
                        this.summarySelectAllMateria = false
                        this.commitCompetition()
                    }
                    if (Object.values(newValue).every(x => x === false)) {
                        this.summarySelectAllMateria = true
                        this.commitCompetition()
                    }
                }
            },
            configuration: {
                deep: true,
                handler (newValue, oldValue) {
                    const context = this
                    if (this.filtersReady) {
                        this.commitCompetition()
                        if (!_.isEqual(this.savedConfiguration, newValue) && this.verifyIfRangesAreValid() === true) {
                            if (newValue.keyWords.length === 0 || newValue.keyWords[0] === "") {
                                if (this.savedConfiguration.typeSearchWordKey !== newValue.typeSearchWordKey) {
                                    this.savedConfiguration = _.cloneDeep(newValue)
                                    return
                                }
                            }
                            this.fieldLoader = true
                            this.savedConfiguration = _.cloneDeep(newValue)
                            const nonce = this.generateNonce()
                            try {
                                clearTimeout(performSearchTimeout)
                            } catch (e) { }
                            performSearchTimeout = setTimeout(function() { context.getStats(newValue, nonce) }.bind(null, newValue, nonce), 1000)
                        }
                    }
                }
            },
            orderQuestions: function () {
                this.commitCompetition()
            },
            orderAnswers: function () {
                this.commitCompetition()
            },
            /**
             * Custom trial
             */
            'customTrial.subjects': {
                deep: true,
                handler (newValue, oldValue) {
                    if (this.filtersReady && !this.resetPending) {
                        newValue.forEach(value => {
                            if (value.isChecked === false) {
                                $("#custom-trial-" + value.id_materia).parent().children().addClass('bg-disabled')
                                $("#custom-trial-" + value.id_materia).parent().children().addClass('cursorNotAllowed')
                                $("#custom-trial-" + value.id_materia).parent().children().children().addClass('cursorNotAllowed')
                                $("#custom-trial-" + value.id_materia).parent().children().children().attr('disabled', 'disabled')
                            } else {
                                $("#custom-trial-" + value.id_materia).parent().children().removeClass('bg-disabled')
                                $("#custom-trial-" + value.id_materia).parent().children().removeClass('cursorNotAllowed')
                                $("#custom-trial-" + value.id_materia).parent().children().children().removeClass('cursorNotAllowed')
                                $("#custom-trial-" + value.id_materia).parent().children().children().removeAttr('disabled')
                            }
                        })
                        this.commitCompetition(true)
                    }
                }
            },
            'customTrial.duration': {
                deep: true,
                handler (newValue, oldValue) {
                    if (this.filtersReady && !this.resetPending) {
                        this.commitCompetition(true)
                    }
                }
            },
            'customTrial.scores': {
                deep: true,
                handler (newValue, oldValue) {
                    if (this.filtersReady && !this.resetPending) {
                        this.commitCompetition(true)
                    }
                }
            }
        },
        mounted() {
            this.backToTop()
            this.verifyCompetition()
            this.addEventResetModalStatistics()
        },
        methods: {
            ...mapActions(MODULES.COMPETITION, [COMPETITION_ACTIONS.GET_ADDITIONAL_RESOURCES, COMPETITION_ACTIONS.GET_COMPETITION, COMPETITION_ACTIONS.GET_SIMULATIONS, COMPETITION_ACTIONS.GET_SIMULATION, COMPETITION_ACTIONS.GET_RANKING, COMPETITION_ACTIONS.GET_SIMULATION_RESULTS, COMPETITION_ACTIONS.GET_SIMULATION_ANSWERS]),
            ...mapActions(MODULES.PROFILE, [PROFILE_ACTIONS.GET_LEVEL, PROFILE_ACTIONS.UPDATE_LEVEL]),
            addEventResetModalStatistics () {
                $('#delStatisticsModal').on('hidden.bs.modal', (e) => {
                    this.modalSelectAllMateria = true
                    this.modalStatsRemote = true
                    this.modalSubjects = {}
                })
            },
            verifyIfNeedToDisableConfirmDelStatsButton () {
                if (!_.isEmpty(this.modalSubjects)) {
                    const subjects = this.subjects.map(s => this.modalSubjects[s.id_materia]).filter(s => s === true)
                    return (subjects.length < 1)
                }
                return this.modalSelectAllMateria === false

            },
            verifyIfQuizHasImage () {
                if (this.quizHasImage) {
                    $('#modalQuizHasImages').modal('show')
                } else {
                    this.setupExercise(this.exerciseMode.ASCOLTATA)
                }
            },
            setupExercise (exerciseMode) {
                if (!this.fieldLoader && this.summedQuestions > 0) {
                    if (this.profile.livello < 502) {
                        this.$emit('is-components-ready', 'competitionDetail', false)
                        const knowledgeLevels = []
                        if (this.summary.ancoraUnPiccoloSforzo) { knowledgeLevels.push(LivelliConoscenzaEnum.ANCORA_UN_PICCOLO_SFORZO) }
                        if (this.summary.nonRisposte) { knowledgeLevels.push(LivelliConoscenzaEnum.NON_RISPOSTE) }
                        if (this.summary.nonLeSai) { knowledgeLevels.push(LivelliConoscenzaEnum.NON_LE_SAI) }
                        if (this.summary.ripetile) { knowledgeLevels.push(LivelliConoscenzaEnum.RIPETILE) }
                        if (this.summary.leSai) { knowledgeLevels.push(LivelliConoscenzaEnum.LE_SAI) }
                        esercitazioneManager.setupEsercitazione({
                            sortRisposte: this.orderAnswers,
                            sortDomande: this.orderQuestions,
                            livelli_conoscenza: knowledgeLevels,
                            modalita_esercitazione: exerciseMode,
                        })
                            .then((uuid) => {
                                this.$emit('is-components-ready', 'competitionDetail', true)
                                if (exerciseMode === this.exerciseMode.ASCOLTATA) {
                                    esercitazioneManager.getEsercitazioneMeta()
                                        .then((res) => {
                                            if (res.n_quiz === 0 || res.n_quiz === undefined) {
                                                this.showModalError('competitionDetail.modale.errore.nonCiSonoQuiz')
                                            } else {
                                                this.$router.push({ name: routesEnums.EXERCISE, params: { uuid: uuid, type: competitionDetailTabsEnums.EXERCISE } })
                                            }
                                        })
                                        .catch(() => {
                                            this.showModalError('competitionDetail.modale.errore.generale')
                                        })
                                } else {
                                    this.$router.push({ name: routesEnums.EXERCISE, params: { uuid: uuid, type: competitionDetailTabsEnums.EXERCISE } })
                                }
                            })
                            .catch(() => {
                                this.showModalError('competitionDetail.modale.errore.generale')
                            })
                    } else {
                        $('#modalLimitQuiz').modal({backdrop: 'static', keyboard: false})
                    }
                }
            },
            verifyIfRangesAreValid () {
                return !Number.isNaN(parseInt(this.configuration.rangeStart))
                    && !Number.isNaN(parseInt(this.configuration.rangeStop))
                    && this.configuration.rangeStart <= this.configuration.rangeStop
            },
            verifyResource (resource) {
                return resource !== '' && resource !== null && resource !== undefined;
            },
            generateNonce () {
                const nonce = +new Date()
                lastSearchNonce = nonce
                return nonce
            },
            validateRangeStart () {
                if (this.configuration.rangeStart === "" || this.configuration.rangeStart == null) {
                    this.configuration.rangeStart = 1
                }
            },
            validateRangeStop () {
                if (this.configuration.rangeStop === "" || this.configuration.rangeStop == null) {
                    this.configuration.rangeStop = this.rangeStopMax
                    this.metaConfig.isRangeStopCustom = false
                } else {
                    const rangeStartParsed = parseInt(this.configuration.rangeStart)
                    const rangeStopParsed = parseInt(this.configuration.rangeStop)
                    if (!Number.isNaN(rangeStartParsed) && !Number.isNaN(rangeStopParsed) && rangeStopParsed < rangeStartParsed) {
                        this.configuration.rangeStop = rangeStartParsed
                    } else if (Number.isNaN(rangeStopParsed)) {
                        this.configuration.rangeStop = this.rangeStopMax
                        this.metaConfig.isRangeStopCustom = false
                    }
                }
                this.$forceUpdate()
            },
            setRangeStart (event) {
                const valueParsed = parseInt(event.target.value)
                if (event.target.value === "" || event.target.value == null) {
                    return
                } else if (Number.isNaN(valueParsed) || valueParsed === 0) {
                    this.configuration.rangeStart = 1
                } else if (valueParsed > this.configuration.rangeStop) {
                    this.configuration.rangeStart = this.configuration.rangeStop
                }
                else {
                    this.configuration.rangeStart = valueParsed
                }
                this.$forceUpdate()
            },
            setRangeStop (event, immediate = false) {
                const valueParsed = parseInt(event.target.value)
                if (event.target.value === "" || event.target.value == null) {
                    return
                } else if (Number.isNaN(valueParsed) || valueParsed === 0) {
                    this.configuration.rangeStop = this.rangeStopMax
                } else if (valueParsed > this.rangeStopMax) {
                    this.configuration.rangeStop = this.rangeStopMax
                } else if (immediate && valueParsed < this.configuration.rangeStart) {
                    this.configuration.rangeStop = this.configuration.rangeStart
                }
                else {
                    this.configuration.rangeStop = valueParsed
                }
                this.metaConfig.isRangeStopCustom = true
                this.$forceUpdate()
            },
            onAllCheckboxClicked: function (e) {
                if (this.isCheckboxSubjectEmpty) {
                    e.preventDefault()
                    e.stopPropagation()
                }
                this.configuration.checkboxSubjects = {}
            },
            onSelectAllLevelsClick(e) {
                if (this.summarySelectAllMateria) {
                    e.preventDefault()
                    e.stopPropagation()
                }
            },
            /** Controlla se i filtri attualmente selezionati sono custom o meno
             */
            isFilterDefault() {
                return (
                    this.orderQuestions === QuizOrderEnum.ALFABETICO
                    && this.orderAnswers === AnswerOrderEnum.CASUALE
                    && this.areKeywordsEmpty(this.configuration.keyWords)
                    && this.configuration.typeSearchWordKey === SearchModeEnum.ANY
                    && this.configuration.favorite === false
                    && this.configuration.commento === false
                    && this.configuration.rangeEnabled === false
                )
            },
            areKeywordsEmpty(kwArray = []) {
                return kwArray.every(x => x == null || x === "")
            },
            verifyIfConfigurationFiltersIsEdit () {
                if (this.selectedCompetition !== null
                    && this.selectedCompetition.filters !== undefined && this.selectedCompetition.filters.configuration !== undefined
                    || $('#collapseExample').hasClass('show')) {
                    return 'show'
                }
            },
            getCompetitionStatus () {
                statsManager.getCompletamento(this.selectedCompetition.id_concorso)
                    .then((res) => this.stateOfCompletion = res)
            },
            parsePct (v) {
                return getNumberBetween(v, 0, 100)
            },
            getSubjects () {
                const context = this
                worker.getMaterie(this.selectedCompetition.id_concorso)
                    .then((res) => {
                        this.subjects = res
                        this.setTotDomande()
                        this.checkIfDBIsAlreadyDownloaded(true)
                        this.isLoading = false
                        this.setupSubjectsCustomTrial()
                        this.setupUserConfiguration()
                        context.$nextTick(() => {
                            $('#modalDownloadDb').modal('hide')
                            context.init()
                        })
                    })
                    .catch(() => {
                        $('#modalDownloadDb').modal('hide')
                        this.showModalErrorBlocker('competitionDetail.modale.errore.materie')
                    })
            },
            setTotDomande () {
                if (this.isCompetitionWithoutAnswers) {
                    this.totDomande = this.computeAnswersToBeAssignOrAlreadyAssign('n_domande_by_materia')
                } else {
                    this.totDomande = this.metaData.n_domande
                }
            },
            commitCompetition (onlySubjectsCustomTrial) {
                if (onlySubjectsCustomTrial !== undefined && onlySubjectsCustomTrial !== null && onlySubjectsCustomTrial) {
                    this.selectedCompetition.customTrial = _.cloneDeep(this.customTrial)
                } else {
                    // Update the filters of the competition before commit on vuex
                    this.selectedCompetition.filters.configuration = this.configuration
                    this.selectedCompetition.filters.orders.orderAnswers = this.orderAnswers
                    this.selectedCompetition.filters.orders.orderQuestions = this.orderQuestions
                    this.selectedCompetition.filters.summary = this.summary
                    this.selectedCompetition.filters.metaConfig = this.metaConfig
                }
                const newSelectedCompetitions = [...this.selectedCompetitions]
                newSelectedCompetitions[this.selectedCompetition.indice] = this.selectedCompetition
                this.$store.commit(MODULES.COMPETITION + "/" + COMPETITION_MUTATIONS.SET_SELECTED_COMPETITIONS, newSelectedCompetitions)
            },
            /** Verifica che esista all’interno di selected competition il concorso con id corrispondente e lo estrae
             */
            verifyCompetition () {
                this.$emit('is-components-ready', 'competitionDetail', false)

                // Rimuoviamo eventuali query param dalla url
                let urlPath = `${process.env.BASE_URL}/banca-dati`.replace("//", "/");
                window.history.pushState({urlPath},"",urlPath)
                this.verifyRouteTypeWithTab()
                const context = this
                if (this.idCompetition !== undefined) {
                    this.selectedCompetition = _.cloneDeep(_.find(this.selectedCompetitions, function (c) {
                        return context.idCompetition === c.id_concorso
                    }))
                    this.getSimulations({
                        idConcorso: this.selectedCompetition.id_concorso
                    })
                        .then((res) => {
                            this.simulations = res
                            if (res.length === 1) {
                                this.getSimulationDetail(0)
                            }
                        })
                    this.getSelectedCompetition(this.selectedCompetition.id_concorso)
                } else {
                    this.$emit('is-components-ready', 'competitionDetail', true)
                    this.$router.push({ name: 'Home' })
                }
            },
            verifyRouteTypeWithTab () {
                if (this.$route.query.type !== this.tabType) {
                    this.tabType = competitionDetailTabsEnums.EXERCISE
                }
            },
            getSelectedCompetition (idCompetition) {
                const context = this
                this.validateCompetition(idCompetition)
                    .then(() => {
                        context.downloadCompetitionQuestions()
                        context.getResources()
                    })
                    .catch(() => {
                        context.selectedCompetitions.splice(context.selectedCompetition.indice, 1)
                        this.showModalErrorBlocker('competitionDetail.modale.errore.idConcorso')
                    })
            },
            downloadCompetitionQuestions () {
                const context = this
                this.checkIfDBIsAlreadyDownloaded()
                    .then(() => {
                        context.syncStats()
                            .finally(() => {
                                context.getLevel()
                                .finally(() => {
                                    context.countRequests = 0
                                    context.getCompetitionStatus()
                                    context.getSubjects()
                                })
                            })
                    })
                    .catch(() => {
                        context.countRequests++
                        if (context.countRequests > 1) {
                            this.showModalErrorBlocker('competitionDetail.modale.errore.aggiungiConcorso')
                        } else {
                            context.getCompetitionSelected(context.selectedCompetition.id_concorso)
                        }
                    })
            },
            /** Prende i metadati
             */
            checkIfDBIsAlreadyDownloaded (performResetSubjects) {
                const context = this
                return worker.getMeta(this.selectedCompetition.id_concorso)
                    .then((res) => {
                        context.isCompetitionWithoutAnswers = (res.n_domande > res.n_domande_con_risposta)
                        context.metaData = res
                        if (performResetSubjects !== undefined && performResetSubjects) {
                            if (context.selectedCompetition.customTrial !== undefined && context.selectedCompetition.customTrial.subjects !== undefined && _.isEmpty(context.selectedCompetition.customTrial.subjects)) {
                                context.resetCustomTrial()
                            } else {
                                context.validateSubjects()
                            }
                            context.setTotDomande()
                        }
                        context.checkIfKeyExists()
                        context.isMetaDataForAssignLoading = false
                        context.isUpdate = moment(context.selectedCompetition.data_ultimo_aggiornamento).isAfter(context.metaData.data_ultimo_aggiornamento);
                    })
            },
            validateSubjects () {
                this.customTrial.subjects = Object.entries(this.metaData.n_domande_by_materia)
                    .map(([k, v]) => {
                        const subject = _.find(this.selectedCompetition.customTrial.subjects, (s) => s.id_materia === k)
                        if (subject !== undefined) {
                            if (subject.selectedQuiz > v) {
                                subject.selectedQuiz = v
                            }
                            return subject
                        } else {
                            return {
                                id_materia: k,
                                isChecked: true,
                                selectedQuiz: 0,
                                materia_uff: _.get(_.find(this.subjects, {id_materia: k}), 'materia_uff', 'N/A')
                            }
                        }
                    })
                    .filter(x => x !== undefined)
            },
            getCompetitionSelected (idCompetition) {
                const context = this
                this.getCompetition({
                    idConcorso: idCompetition
                })
                    .then(() => {
                        context.addCompetition()
                    })
                    .catch(() => {
                        this.showModalErrorBlocker('competitionDetail.modale.errore.idConcorso')
                    })
            },
            async fetchDataBase(url_domande, context) {
                // Step 1: start the fetch and obtain a reader
                let response = await fetch(url_domande);
                if (response.status !== 200) {
                    return undefined
                }
                const reader = response.body.getReader();

                // Step 2: get total length
                const contentLength = +response.headers.get('Content-Length');

                // Step 3: read the data
                let receivedLength = 0; // received that many bytes at the moment
                let chunks = []; // array of received binary chunks (comprises the body)
                while(true) {
                    const {done, value} = await reader.read();

                    if (done) {
                        context.downloadProgressValue = 100
                        break;
                    }

                    chunks.push(value);
                    receivedLength += value.length;

                    context.downloadProgressValue = Math.floor(receivedLength * 100 / contentLength);
                }

                // Step 4: concatenate chunks into single Uint8Array
                let chunksAll = new Uint8Array(receivedLength); // (4.1)
                let position = 0;
                for(let chunk of chunks) {
                    chunksAll.set(chunk, position); // (4.2)
                    position += chunk.length;
                }

                return chunksAll;
            },
            addCompetition () {
                const context = this
                $('#modalDownloadDb').modal({backdrop: 'static', keyboard: false})
                this.$emit('is-components-ready', 'competitionDetail', false)
                this.fetchDataBase(this.selectedCompetition.url_domande, context)
                    .then((ubuffer) => {
                        window.fbq('trackCustom', this.$route.name  === routesEnums.PATH_DETAIL_WRAPPER ? 'materia_downloaded' :  'concorso_downloaded')
                        firebase.analytics().logEvent(this.$route.name  === routesEnums.PATH_DETAIL_WRAPPER ? 'materia_downloaded' :  'concorso_downloaded')
                        worker.addConcorso(this.selectedCompetition, ubuffer)
                            .then(() => {
                                context.downloadCompetitionQuestions()
                            })
                            .catch(() => {
                                setTimeout(() => {
                                    $('#modalDownloadDb').modal('hide')
                                    context.showModalErrorBlocker('competitionDetail.modale.errore.aggiungiConcorso')
                                }, 500)
                            })
                    })
                    .catch(() => {
                        setTimeout(() => {
                            $('#modalDownloadDb').modal('hide')
                            context.showModalError('competitionDetail.modale.errore.downloadDomande')
                        }, 500)
                    })
            },
            setupUserConfiguration () {
                if (this.selectedCompetition.customTrial !== undefined) {
                    if (this.selectedCompetition.customTrial.subjects !== undefined && this.selectedCompetition.customTrial.subjects.length > 0) {
                        this.selectedCompetition.customTrial.subjects.forEach(s => {
                            s.selectedQuiz = (s.selectedQuiz === undefined || s.selectedQuiz == null) ? 0 : s.selectedQuiz
                        })
                        this.customTrial.subjects = _.cloneDeep(this.selectedCompetition.customTrial.subjects)
                    }
                    if (this.selectedCompetition.customTrial.duration !== undefined) {
                        this.customTrial.duration = this.selectedCompetition.customTrial.duration
                    }
                    if (this.selectedCompetition.customTrial.scores !== undefined) {
                        this.customTrial.scores = this.selectedCompetition.customTrial.scores
                    }
                } else {
                    this.selectedCompetition.customTrial = {
                        subjects: [],
                        duration: {
                            HH: 1,
                            mm: 0,
                            ss: 0
                        },
                        scores: {
                            noAnswers: '0',
                            wrong: '-1',
                            right: '1'
                        }
                    }
                }
                if (this.selectedCompetition.filters === undefined) {
                    this.configuration.rangeStop = this.rangeStopMax
                    this.selectedCompetition.filters = {
                        configuration: this.configuration,
                        orders: {
                            orderAnswers: this.orderAnswers,
                            orderQuestions: this.orderQuestions
                        },
                        summary: this.summary,
                        metaConfig: this.metaConfig,
                    }
                    this.summary.nonRisposte = true
                } else {
                    this.orderQuestions = this.selectedCompetition.filters.orders.orderQuestions
                    this.orderAnswers = this.selectedCompetition.filters.orders.orderAnswers
                    this.configuration = this.selectedCompetition.filters.configuration
                    this.summary = this.selectedCompetition.filters.summary

                    // Controlliamo che isRangeStopCustom sia effettivamente definito, lo mettiamo di default a false altrimenti
                    this.metaConfig.isRangeStopCustom = _.get(this.selectedCompetition.filters, "metaConfig.isRangeStopCustom", false)
                }
                this.savedConfiguration = _.cloneDeep(this.configuration)
                this.$nextTick(() => {
                    this.filtersReady = true
                    this.isInitialFilterDefault = this.isFilterDefault()
                    this.messageFilter = ((this.isInitialFilterDefault) ? this.$t('competitionDetail.apriImpostazioni') : this.$t('competitionDetail.chiudiImpostazioni'))
                    this.$nextTick(() => {
                        this.fieldLoader = true
                        this.getStats(this.configuration, this.generateNonce())
                    })
                })
            },
            /** Controlla se l'id corrisponde a una banca dati effettivamente esistente
             */
            validateCompetition (idCompetition) {
                const context = this
                return new Promise((resolve, reject) => {
                    const competition = _.find(context.competitions, function (c) { return c.id_concorso === idCompetition })
                    if (competition !== null && competition !== undefined) {
                        resolve()
                    } else {
                        reject()
                    }
                })
            },
            syncStats () {
                return statsManager.syncStats(this.selectedCompetition.id_concorso)
            },
            init () {
                const context = this
                $( document ).ready(function() {
                    $('.selectize').selectize()

                    const selectizeTags = $('#selectize-tags').selectize({
                        delimiter: ' ',
                        persist: false,
                        create: function(input) {
                            return {
                                value: input,
                                text: input
                            }
                        },
                        render: {
                            option_create: function(data, escape) {
                                return '<div class="create">' + context.$t('competitionDetail.selectize.aggiungi') + '<strong> ' + escape(data.input) + '</strong>&hellip;</div>';
                            }
                        }
                    })

                    selectizeTags.off('change').on('change', (event) => {
                        context.configuration.keyWords = event.target.value.split(' ')
                    })

                    $("input[name='rangeStart']").TouchSpin({
                        min: 1,
                        max: Infinity,
                        step: 1
                    });

                    $("input[name='rangeStop']").TouchSpin({
                        min: 1,
                        max: Infinity,
                        step: 1
                    });

                    context.customTrial.subjects.forEach((sub, index) => {
                        $("#custom-trial-" + sub.id_materia).TouchSpin({
                            min: 0,
                            max: context.metaData.n_domande_by_materia[sub.id_materia],
                            step: 1
                        });

                        $("#custom-trial-" + sub.id_materia).on('touchspin.on.startspin', (event) => {
                            context.setRangeCustomTrial(event, index)
                        })

                        if (sub.isChecked === false) {
                            context.$nextTick(() => {
                                $("#custom-trial-" + sub.id_materia).parent().children().addClass('bg-disabled')
                                $("#custom-trial-" + sub.id_materia).parent().children().addClass('cursorNotAllowed')
                                $("#custom-trial-" + sub.id_materia).parent().children().children().attr('disabled', 'disabled')
                                $("#custom-trial-" + sub.id_materia).parent().children().children().addClass('cursorNotAllowed')
                            })
                        }
                    })

                    $("input[name='rangeStart']").on('touchspin.on.startspin', (event) => {
                        context.setRangeStart(event)
                    })

                    $("input[name='rangeStop']").on('touchspin.on.startspin', (event) => {
                        context.setRangeStop(event, true)
                    })

                    $("#button-lettura").mouseenter(function() {
                        $("#button-lettura").find('span').text("Avvia Modalità Lettura").fadeIn(400);
                    });
                    $("#button-ascolto").mouseenter(function() {
                        $("#button-ascolto").find('span').text("Avvia Modalità Ascolto").fadeIn(400);
                    });

                    $("#avvia-test, #button-ascolto, #button-lettura").mouseleave(function() {
                        $("#avvia-test, #button-ascolto, #button-lettura").find('span').text("").fadeOut(300);
                    });

                    if (context.isUpdate) {
                        $('#modalUpdateDb').modal('show')
                        $('#button-update-db').click(() => {
                            context.getCompetitionSelected(context.selectedCompetition.id_concorso)
                        })
                    }

                    context.$nextTick(() => {
                        context.$emit('is-components-ready', 'competitionDetail', true)
                    })
                })
            },
            /** Scarica le risorse aggiuntive.
             */
            getResources () {
                const context = this
                this.getAdditionalResources(this.selectedCompetition.id_concorso)
                    .then((response) => {
                        if (response !== null) {
                            const resource = _.find(Object.keys(response), (r) => response[r] !== null && response[r] !== '')
                            if (resource !== undefined && resource !== null) {
                                context.additionalResource = response
                                context.additionalResource.show = true
                            }
                        }
                    })
                    .catch((error) => {
                        if (error.response.status !== 404) {
                            console.error(error)
                        }
                    })
            },
            getStats(filters, nonce) {
                const context = this
                const query = {}
                if (filters !== undefined) {
                    query.id_materie = _.filter(Object.keys(filters.checkboxSubjects), (s) => filters.checkboxSubjects[s] === true)
                    query.search = this.areKeywordsEmpty(filters.keyWords) ? [] : filters.keyWords
                    query.preferiti = filters.favorite
                    query.commento = filters.commento
                    query.search_mode = filters.typeSearchWordKey
                    if (this.configuration.rangeEnabled) {
                        query.ix_start = (filters.rangeStart - 1)
                        query.ix_end = (filters.rangeStop - 1)
                    }
                }
                statsManager.getStatsByFilter(context.selectedCompetition.id_concorso, query)
                    .then((stats) => {
                        if (nonce === lastSearchNonce) {
                            context.currentStats = {
                                nonRisposte: stats[LivelliConoscenzaEnum.NON_RISPOSTE],
                                nonLeSai: stats[LivelliConoscenzaEnum.NON_LE_SAI],
                                ripetile: stats[LivelliConoscenzaEnum.RIPETILE],
                                ancoraUnPiccoloSforzo: stats[LivelliConoscenzaEnum.ANCORA_UN_PICCOLO_SFORZO],
                                leSai: stats[LivelliConoscenzaEnum.LE_SAI],
                                totDomande: stats.tot
                            }
                            context.quizHasImage = stats.has_images.tot
                            context.fieldLoader = false
                        }
                    })
                    .catch(() => {
                        if (nonce === lastSearchNonce) {
                            context.fieldLoader = false
                            this.showModalErrorBlocker('competitionDetail.modale.errore.statistiche')
                        }
                    })
            },
            resetSummaryCheckbox () {
                this.summary.ancoraUnPiccoloSforzo = false
                this.summary.nonRisposte = false
                this.summary.nonLeSai = false
                this.summary.ripetile = false
                this.summary.leSai = false
            },
            emptyStats () {
                if (!this.verifyIfNeedToDisableConfirmDelStatsButton()) {
                    const context = this
                    this.$emit('is-components-ready', 'competitionDetail', false)
                    let subjects = undefined
                    if (!_.isEmpty(this.modalSubjects)) {
                        subjects = { id_materie: Object.keys(this.modalSubjects) }
                    }
                    statsManager.resetStatsRemote(this.selectedCompetition.id_concorso, subjects, {remote: this.modalStatsRemote})
                        .then(() => {
                            this.$emit('is-components-ready', 'competitionDetail', true)
                            context.fieldLoader = true
                            context.isMetaDataForAssignLoading = true
                            context.getStats(context.configuration, context.generateNonce())
                            context.checkIfDBIsAlreadyDownloaded(true)
                            Swal.fire({
                                title: context.$t('competitionDetail.sweetAlert.azzeraStatistiche.title'),
                                text: context.$t('competitionDetail.sweetAlert.azzeraStatistiche.text'),
                                icon: 'success',
                                confirmButtonText: this.$t('generale.ok')
                            })
                        })
                        .catch(() => {
                            this.showModalError('competitionDetail.modale.errore.azzeraStatistiche')
                        })
                }
            },
            getKnowledgeLevel () {
                let query = undefined
                const isSummarySelected = _.filter(Object.keys(this.summary), (s) => this.summary[s] === true)
                if (isSummarySelected !== undefined && isSummarySelected.length > 0) {
                    query = []
                    isSummarySelected.forEach((summarySelected) => {
                        if (summarySelected === "ancoraUnPiccoloSforzo") {
                            query.push(LivelliConoscenzaEnum.ANCORA_UN_PICCOLO_SFORZO)
                        }
                        if (summarySelected === "leSai") {
                            query.push(LivelliConoscenzaEnum.LE_SAI)
                        }
                        if (summarySelected === "nonLeSai") {
                            query.push(LivelliConoscenzaEnum.NON_LE_SAI)
                        }
                        if (summarySelected === "nonRisposte") {
                            query.push(LivelliConoscenzaEnum.NON_RISPOSTE)
                        }
                        if (summarySelected === "ripetile") {
                            query.push(LivelliConoscenzaEnum.RIPETILE)
                        }
                    })
                }
                return query
            },
            downloadQuestions () {
                if (!this.fieldLoader && this.summedQuestions > 0) {
                    if (this.profile.livello > 1) {
                        $('#dlModalQuestions').modal('show')
                    } else {
                        if (this.quizHasImage) {
                            let imageDbPromise = undefined;
                            if (this.selectedCompetition.url_immagini === imagesCache.url && imagesCache.promise !== undefined) {
                                imageDbPromise = imagesCache.promise;
                            }
                            else {
                                imagesCache.url = undefined;
                                const url_immagini = this.selectedCompetition.url_immagini;
                                this.downloadProgressValue = 0
                                $('#modalDownloadDb').data('bs.modal', null)
                                $('#modalDownloadDb').modal({backdrop: 'static', keyboard: false})
                                imagesCache.promise = this.fetchDataBase(url_immagini, this);
                                imagesCache.promise.then(() => {imagesCache.url = url_immagini});
                                imageDbPromise = imagesCache.promise;
                            }
                            imageDbPromise
                                .then((ubuffer) => {
                                    if (ubuffer !== undefined) {
                                        setTimeout(() => {
                                            $('#modalDownloadDb').modal('hide')
                                        }, 500)
                                        imagesClient.setupCache(ubuffer)
                                            .then(() => {
                                                this.startBuildPdf()
                                            })
                                            .catch(() => {
                                                this.showModalError('competitionDetail.modale.errore.setupCache')
                                            })
                                    } else {
                                        setTimeout(() => {
                                            $('#modalDownloadDb').modal('hide')
                                            this.$emit('is-components-ready', 'competitionDetail', false)
                                        }, 500)
                                    }
                                })
                                .catch(() => {
                                    setTimeout(() => {
                                        $('#modalDownloadDb').modal('hide')
                                        this.showModalError('competitionDetail.modale.errore.downloadImmagini')
                                    }, 500)
                                })
                        } else {
                            setTimeout(() => {
                                this.$emit('is-components-ready', 'competitionDetail', false)
                                this.startBuildPdf()
                            }, 500)
                        }
                    }
                }
            },
            startBuildPdf () {
                const context = this
                context.$emit('is-components-ready', 'competitionDetail', false)
                const livelliConoscenza = this.getKnowledgeLevel()
                const query = livelliConoscenza ? { livelliConoscenza } : undefined
                const fileName = this.selectedCompetition.concorso.replace(/ /g, "_")
                worker.getDomandeStampa(this.selectedCompetition.id_concorso, query)
                    .then((res) => {
                        const pdfMake = require('pdfmake/build/pdfmake.js');
                        if (pdfMake.vfs === undefined) {
                            const pdfFonts = require('pdfmake/build/vfs_fonts.js');
                            pdfMake.vfs = pdfFonts.pdfMake.vfs;
                        }
                        this.getDocDefinition(res)
                            .then((resultBody) => {

                                function handleCreatePdfError() {
                                    context.showModalError('competitionDetail.modale.errore.erroreCreazionePDF')
                                    context.$emit('is-components-ready', 'competitionDetail', true)
                                    window.removeEventListener('unhandledrejection', handleCreatePdfError);
                                }

                                window.addEventListener('unhandledrejection', handleCreatePdfError);

                                pdfMake.createPdf(resultBody).download(fileName, () => {
                                    window.removeEventListener('handleCreatePdfError', handleCreatePdfError);
                                    context.$emit('is-components-ready', 'competitionDetail', true)
                                });

                            })
                            .catch(() => {
                                this.showModalError('competitionDetail.modale.errore.downloadDomande')
                            })
                    })
                    .catch(() => {
                        this.showModalError('competitionDetail.modale.errore.downloadDomandeStampa')
                    })
            },
            async getDocDefinition (res) {
                const subjects = Object.keys(res)
                const computedArrays = await Promise.all(Object.values(res).map(risultati => this.getComputedArray(risultati)))
                return {
                    pageSize: 'A4',
                    pageOrientation: 'landscape',
                    content: [
                        {
                            text: [
                                "Concorso: " + this.selectedCompetition.concorso + " \n",
                                "Ente: " + this.selectedCompetition.ente.ente + " \n",
                                "Anno: " + this.selectedCompetition.anno + " \n",
                                "Numero quiz contenuti nel file " + this.summedQuestions + " \n"
                            ],
                            style: "header"
                        },
                        {
                            text: "Nel seguente file trovi i quiz in ordine alfabetico per singola materia con solo la relativa risposta esatta",
                            alignment: "center"
                        },
                        ...computedArrays.flatMap((result, index) => [
                            {
                                pageBreak: 'before',
                                text: [
                                    "Materia: " + (_.find(this.subjects, function (s) { return s.id_materia === subjects[index] })).materia_uff + " \n"
                                ],
                                margin: [20, 20],
                                style: "header"
                            },
                            {
                                table: {
                                    widths: [35, 250, 200, 35, 200],
                                    body: result,
                                    dontBreakRows: true
                                }
                            }
                        ])
                    ],
                    styles: {
                        header: {
                            fontSize: 18,
                            bold: true,
                            alignment: "center"
                        },
                        columnHeader: {
                            fontSize: 16,
                            color: "red",
                            bold: true
                        },
                        columnID: {
                            fontSize: 9,
                            color: "red"
                        }
                    },
                    footer: function(currentPage, pageCount) {
                        return [
                            { text: 'Pagina: ' + currentPage.toString(), alignment: 'center', fontSize: 8, margin: [5, 5] }
                        ]
                    }
                }
            },
            getComputedArray (results) {
                let data = []
                data.push([
                    { text: 'ID Ufficiale', style: "columnID" },
                    { text: 'Domanda', style: "columnHeader", colSpan: 2 },
                    {},
                    { text: 'Risposta Esatta', style: "columnHeader", colSpan: 2 },
                    {}
                ])
                const promiseData = results.map(function (result) {
                    if (result.url_img !== undefined) {
                        return imagesClient.get(result.url_img)
                            .then(validateImage)
                            .then((res) => {
                                return ([
                                    result.indice_uff,
                                    { text: result.domanda },
                                    { text: result.risposta, colSpan: 2 },
                                    '',
                                    { image: res, width: 200, colSpan: 1 }
                                ])
                            })
                            .catch(() => {
                                $("#modalPdfImageNotFound").modal('show')
                                return [
                                    result.indice_uff,
                                    { text: result.domanda },
                                    { text: result.risposta, colSpan: 2 },
                                    '',
                                    { text: "", colSpan: 1 }
                                ]
                            })
                    } else {
                        return new Promise(resolve => {
                            resolve([
                                result.indice_uff,
                                { text: result.domanda, colSpan: 2 },
                                '',
                                { text: result.risposta, colSpan: 2 },
                                ''
                            ])
                        })
                    }
                });
                return Promise.all(promiseData)
                    .then((res) => {
                        res.forEach(result => {
                            data.push(result)
                        })
                        return data
                    })
            },
            forceSync(type) {
                this.$emit('is-components-ready', 'competitionDetail', false)
                if (type === 'fromWeb') {
                    statsManager.forceSyncFromRemote(this.metaData.id_concorso)
                        .then(() => {
                            this.fieldLoader = true
                            this.showSweetAlertSync(this.$t('competitionDetail.sweetAlert.forzaSincronizzazione.dalWeb'))
                            this.checkIfDBIsAlreadyDownloaded(true)
                            this.getStats(this.configuration, this.generateNonce())
                        })
                        .catch(() => {
                            this.showModalSyncError('competitionDetail.modale.errore.forceSync')
                        })
                } else {
                    statsManager.forceSyncToRemote(this.metaData.id_concorso)
                        .then(() => {
                            this.showSweetAlertSync(this.$t('competitionDetail.sweetAlert.forzaSincronizzazione.versoIlWeb'))
                        })
                        .catch(() => {
                            this.showModalSyncError('competitionDetail.modale.errore.forceSync')
                        })
                }
            },
            computeCollapseFilterMessage () {
                const context = this
                this.$nextTick(() => {
                    if ($('#collapseExample').hasClass('show')) {
                        context.messageFilter = context.$t('competitionDetail.apriImpostazioni')
                    } else {
                        context.messageFilter = context.$t('competitionDetail.chiudiImpostazioni')
                    }
                })
            },
            backToTop () {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            },
            showModalError(message) {
                const context = this
                this.$emit('is-components-ready', 'competitionDetail', true)
                $('#modalErrorCompetitionDetail').modal('show')
                $('#message-body-error-competition-detail').html(context.$t(message))
            },
            showModalErrorBlocker(message) {
                const context = this
                this.$emit('is-components-ready', 'competitionDetail', true)
                $('#modalErrorCompetition').modal({backdrop: 'static', keyboard: false})
                $('#message-body-competition').html(context.$t(message))
            },
            /** Custom trial
             */
            setupPersonalizedTrial () {
                if (!this.verifyStartButtonCustomTrial()) {
                    let subjects = []
                    let questionsNumber = 0
                    this.customTrial.subjects.forEach(sub => {
                        if (sub.isChecked && sub.selectedQuiz > 0) {
                            questionsNumber += sub.selectedQuiz
                            subjects.push({
                                id_materia: sub.id_materia,
                                numero: sub.selectedQuiz
                            })
                        }
                    })
                    if ((this.profile.livello >= 2 && this.profile.livello < 502 && (((502 - this.profile.livello) - questionsNumber) >= 0)) || this.profile.livello <= 1) {
                        this.$emit('is-components-ready', 'competitionDetail', false)
                        esercitazioneManager.setupProvaPersonalizzata({
                            id_concorso: this.selectedCompetition.id_concorso,
                            durata: this.formatDurationTime(),
                            materie_simulazione: subjects,
                            punteggio_risposte_corrette: this.customTrial.scores.right,
                            punteggio_risposte_sbagliate: this.customTrial.scores.wrong,
                            punteggio_non_risposte: this.customTrial.scores.noAnswers
                        })
                            .then((res) => {
                                firebase.analytics().logEvent('simulazione_custom_started')
                                window.fbq('trackCustom', 'simulazione_custom_started')
                                this.$emit('is-components-ready', 'competitionDetail', true)
                                this.$router.push({ name: 'Exercise', params: { uuid: res, type: competitionDetailTabsEnums.PERSONALIZED_TRIAL } })
                            })
                            .catch(() => {
                                this.$emit('is-components-ready', 'competitionDetail', true)
                            })
                    } else {
                        $('#modalLimitQuiz').modal({backdrop: 'static', keyboard: false})
                    }
                }
            },
            formatDurationTime () {
                let hours = "00"
                let minutes = "00"
                let seconds = "00"
                if (this.customTrial.duration.HH < 10) {
                    hours = "0" + this.customTrial.duration.HH
                } else {
                    hours = this.customTrial.duration.HH
                }
                if (this.customTrial.duration.mm < 10) {
                    minutes = "0" + this.customTrial.duration.mm
                } else {
                    minutes = this.customTrial.duration.mm
                }
                if (this.customTrial.duration.ss < 10) {
                    seconds = "0" + this.customTrial.duration.ss
                } else {
                    seconds = this.customTrial.duration.ss
                }
                return hours + ':' + minutes + ':' + seconds
            },
            setRangeCustomTrial (event, index) {
                const valueParsed = parseInt(event.target.value)
                if (event.target.value === "" || event.target.value == null) {
                    this.customTrial.subjects[index].selectedQuiz = 0
                } else if (Number.isNaN(valueParsed) || this.customTrial.subjects[index].selectedQuiz === undefined || this.customTrial.subjects[index].selectedQuiz === null) {
                    this.customTrial.subjects[index].selectedQuiz = 0
                } else if (valueParsed > this.metaData.n_domande_by_materia[this.customTrial.subjects[index].id_materia]) {
                    this.customTrial.subjects[index].selectedQuiz = this.metaData.n_domande_by_materia[this.customTrial.subjects[index].id_materia]
                }
                else {
                    this.customTrial.subjects[index].selectedQuiz = valueParsed
                }
                this.$forceUpdate()
            },
            setupSubjectsCustomTrial () {
                const context = this
                const diffSubjects = this.subjects.filter(s => !this.customTrial.subjects.some(sct => sct.id_materia === s.id_materia));

                if (diffSubjects.length > 0) {
                    diffSubjects.forEach((sub) => {
                        this.customTrial.subjects.push({
                            id_materia: sub.id_materia,
                            isChecked: true,
                            selectedQuiz: undefined,
                            materia_uff: sub.materia_uff
                        })
                    })
                }
                this.customTrial.subjects = _.filter(this.customTrial.subjects, (cS) => this.metaData.n_domande_by_materia[cS.id_materia] !== undefined && this.metaData.n_domande_by_materia[cS.id_materia] > 0)

                if (this.customTrial.subjects.length > 0) {
                    this.customTrial.subjects.forEach(s => {
                        s.selectedQuiz = Math.floor((context.metaData.n_domande_by_materia[s.id_materia]/context.totDomande) * 100)
                        if (s.selectedQuiz < 1 || s.selectedQuiz > context.totDomande) {
                            s.selectedQuiz = 0
                        }
                    })

                    this.setSubjectValue()
                }
            },
            resetCustomTrial () {
                this.resetPending = true

                this.selectedCompetition.customTrial.subjects = []
                const newSelectedCompetitions = [...this.selectedCompetitions]
                newSelectedCompetitions[this.selectedCompetition.indice] = this.selectedCompetition
                this.$store.commit(MODULES.COMPETITION + "/" + COMPETITION_MUTATIONS.SET_SELECTED_COMPETITIONS, newSelectedCompetitions)

                this.customTrial.subjects.forEach(s => {
                    s.isChecked = true
                    $("#custom-trial-" + s.id_materia).parent().children().removeClass('bg-disabled')
                    $("#custom-trial-" + s.id_materia).parent().children().removeClass('cursorNotAllowed')
                    $("#custom-trial-" + s.id_materia).parent().children().children().removeClass('cursorNotAllowed')
                    $("#custom-trial-" + s.id_materia).parent().children().children().removeAttr('disabled')
                })

                this.setupSubjectsCustomTrial()
                this.customTrial.duration = {
                    HH: 1,
                    mm: 0,
                    ss: 0
                }
                this.customTrial.scores = {
                    right: "1",
                    wrong: "-1",
                    noAnswers: "0"
                }

                this.$nextTick(() => {
                    this.resetPending = false
                })
            },
            setSubjectValue () {
                if ((100 - this.totalSubjectQuizSelected) > 0) {
                    const maxValue = Math.max.apply(Math, this.customTrial.subjects.map(function(s) { return s.selectedQuiz; }))
                    const subjectWithHighQuizValue = _.find(this.customTrial.subjects, function (cS) {
                        return cS.selectedQuiz === maxValue
                    })
                    subjectWithHighQuizValue.selectedQuiz = (subjectWithHighQuizValue.selectedQuiz > 0) ? (maxValue + (100 - this.totalSubjectQuizSelected)) : 0
                }
            },
            verifyStartButtonCustomTrial () {
                if (this.totalSubjectQuizSelected < 1
                    || this.customTrial.duration.HH === ''
                    || this.customTrial.duration.mm === ''
                    || this.customTrial.duration.ss === ''
                    || this.customTrial.scores.noAnswers === ''
                    || this.customTrial.scores.right === ''
                    || this.customTrial.scores.wrong === ''
                    || this.customTrial.scores.right < 0
                    || this.customTrial.scores.wrong > 0
                    || (this.customTrial.duration.HH === 0
                        && this.customTrial.duration.mm === 0
                        && this.customTrial.duration.ss === 0)
                    || (this.customTrial.scores.right === "0"
                        && this.customTrial.scores.wrong === "0"
                        && this.customTrial.scores.noAnswers === "0")
                ) {
                    return true
                } else {
                    return false
                }
            },
            resetTimePicker () {
                if (this.customTrial.duration.HH === '' && this.customTrial.duration.mm === '' && this.customTrial.duration.ss === '') {
                    this.customTrial.duration.HH = 1
                    this.customTrial.duration.mm = 0
                    this.customTrial.duration.ss = 0
                }
            },
            /**
             * Simulations
             */
            setupSimulation (simulation) {
                if ((this.profile.livello >= 2 && this.profile.livello < 502 && (((502 - this.profile.livello) - simulation.numero_domande) >= 0)) || this.profile.livello <= 1) {
                    if (simulation.id_simulazione != null && simulation.id_simulazione !== "") {
                        this.$emit('is-components-ready', 'competitionDetail', false)
                        this.getSimulation({
                            idSimulazione: simulation.id_simulazione,
                            attiva: true
                        })
                            .then((res) => {
                                esercitazioneManager.setupSimulazione({
                                    id_concorso: res.id_concorso,
                                    id_simulazione: res.id_simulazione,
                                    durata: res.durata,
                                    materie_simulazione: res.materie_simulazione,
                                    punteggio_risposte_corrette: res.punteggio_risposte_corrette,
                                    punteggio_risposte_sbagliate: res.punteggio_risposte_sbagliate,
                                    punteggio_non_risposte: res.punteggio_non_risposte
                                })
                                    .then((res) => {
                                        firebase.analytics().logEvent('simulazione_started')
                                        window.fbq('trackCustom', 'simulazione_started')
                                        this.$emit('is-components-ready', 'competitionDetail', true)
                                        this.$router.push({ name: 'Exercise', params: { uuid: res, type: competitionDetailTabsEnums.CHALLENGE } })
                                    })
                                    .catch(() => {
                                        this.$emit('is-components-ready', 'competitionDetail', true)
                                    })
                            })
                            .catch(() => {
                                this.showModalError('competitionDetail.modale.errore.simulazione')
                            })
                    }
                } else {
                    $('#modalLimitQuiz').modal({backdrop: 'static', keyboard: false})
                }
            },
            getSimulationDetail (index) {
                let simulation = this.getSimulationByIndex(index)
                if (simulation !== null && simulation !== undefined && simulation.id_simulazione !== "") {
                    this.$emit('is-components-ready', 'competitionDetail', false)
                    Promise.all([this.getRanking(simulation.id_simulazione), this.getSimulationResults(simulation.id_simulazione)])
                        .then(([resRanking, resSimResults]) => {
                            this.$emit('is-components-ready', 'competitionDetail', true)
                            this.setCurrentSimulation(index)
                            simulation.numero_partecipanti = resRanking.numero_partecipanti
                            simulation.posizione_classifica = resRanking.posizione_classifica
                            simulation.numero_partecipanti_punteggio_superiore = resRanking.numero_partecipanti_punteggio_superiore
                            simulation.punteggio_medio = resRanking.punteggio_medio
                            simulation.simResults = resSimResults.slice(0, 5)
                        })
                        .catch(() => {
                            this.showModalError('competitionDetail.modale.errore.classificaEsitiSimulazione')
                        })
                } else {
                    this.showModalErrorBlocker('competitionDetail.modale.errore.indiceSimulazione')
                }
            },
            getSimulationByIndex (index) {
                if (index !== null && index !== undefined && index >= 0 && index <= (this.simulations.length - 1)) {
                    return this.simulations[index]
                } else {
                    return undefined
                }
            },
            rewatchLastSim (simulation) {
                if (simulation != null && simulation.id_simulazione !== undefined && simulation.id_simulazione !== "") {
                    this.$emit('is-components-ready', 'competitionDetail', false)
                    this.getSimulationAnswers(simulation.id_simulazione)
                        .then((res) => {
                            esercitazioneManager.setupRiepilogo({
                                riepilogo: res,
                                id_concorso: this.metaData.id_concorso,
                                id_simulazione: simulation.id_simulazione,
                                punteggio_non_risposte: simulation.punteggio_non_risposte,
                                punteggio_risposte_corrette: simulation.punteggio_risposte_corrette,
                                punteggio_risposte_sbagliate: simulation.punteggio_risposte_sbagliate
                            })
                                .then((uuid) => {
                                    this.$emit('is-components-ready', 'competitionDetail', true)
                                    this.$router.push({ name: 'Summary', params: { uuid: uuid, type: competitionDetailTabsEnums.CHALLENGE } })
                                })
                                .catch(() => {
                                    this.showModalError('competitionDetail.modale.errore.rivediUltimoConcorso')
                                })
                        })
                        .catch(() => {
                            this.showModalError('competitionDetail.modale.errore.risposteSimulazione')
                        })
                }
            },
            setCurrentSimulation(value) {
                if (value === undefined && this.simulations.length === 1) {
                    this.indexSelectedSimulation = 0
                } else {
                    this.indexSelectedSimulation = value
                }
            },
            /**
             * End simulations
             */
            formatData(data) {
                return moment(data).format('DD-MM-YYYY HH:mm')
            },
            computeNoAnswers(simulation) {
                return (simulation.numero_domande_previste - (simulation.numero_risposte_corrette + simulation.numero_risposte_errate))
            },
            showModalSyncError(message) {
                const context = this
                this.$emit('is-components-ready', 'competitionDetail', true)
                $('#modalErrorForceSync').modal('show')
                $('#message-body-error-force-sync').html(context.$t(message))
            },
            showSweetAlertSync (text) {
                this.$emit('is-components-ready', 'competitionDetail', true)
                Swal.fire({
                    title: this.$t('competitionDetail.sweetAlert.forzaSincronizzazione.titolo'),
                    text: text,
                    icon: 'success',
                    confirmButtonText: this.$t('generale.ok')
                })
            },
            computeAnswersToBeAssignOrAlreadyAssign (field) {
                let answers = 0
                this.subjects.forEach(subject => {
                    if (this.metaData[field][subject.id_materia] !== undefined) {
                        if (field === 'n_domande_senza_risposta_by_materia' && this.metaData.n_domande_assegnate_by_materia[subject.id_materia] !== undefined) {
                            answers += (this.metaData[field][subject.id_materia] - this.metaData.n_domande_assegnate_by_materia[subject.id_materia])
                        } else {
                            answers += this.metaData[field][subject.id_materia]
                        }
                    }
                })
                return answers
            },
            setupAssignAnswers (assign, idSubject) {
                this.$emit('is-components-ready', 'competitionDetail', false)
                $('#modalAnswersToBeAssign').modal('hide')
                $('#modalAssignRightAnswers').modal('hide')
                esercitazioneManager.setupAssegnaRisposte({ assegnate: assign, id_concorso: this.metaData.id_concorso, id_materie: [idSubject]})
                    .then(res => {
                        this.$emit('is-components-ready', 'competitionDetail', true)
                        this.$router.push({ name: 'Exercise', params: { uuid: res, type: competitionDetailTabsEnums.EXERCISE } })
                    })
                    .catch(() => {
                        this.showModalError('competitionDetail.modale.errore.domandeDaAssegnare')
                    })
            },
            computeQuestionsToBeAssignByMateria(subject) {
                if (this.metaData.n_domande_senza_risposta_by_materia[subject.id_materia] !== undefined && this.metaData.n_domande_assegnate_by_materia[subject.id_materia] !== undefined) {
                    return (this.metaData.n_domande_senza_risposta_by_materia[subject.id_materia] - this.metaData.n_domande_assegnate_by_materia[subject.id_materia])
                } else {
                    return (this.metaData.n_domande_senza_risposta_by_materia[subject.id_materia] !== undefined) ? this.metaData.n_domande_senza_risposta_by_materia[subject.id_materia] : 0
                }
            },
            checkIfKeyExists () {
                if (this.metaData.n_domande_assegnate_by_materia !== undefined) {
                    _.map(this.metaData.n_domande_assegnate_by_materia, (value, key) => {
                        if (value === undefined || value === 0) {
                            if (!_.isEmpty(this.configuration.checkboxSubjects)) {
                                if (_.find(this.configuration.checkboxSubjects, (cSValue, cSKey) => cSKey === key) !== undefined) {
                                    this.configuration.checkboxSubjects = _.omit(this.configuration.checkboxSubjects, [key])
                                }
                            }
                        }
                    })
                }
            },
            goToStudyGroup(additionalResource) {
                if (additionalResource.url_facebook && additionalResource.url_telegram) {
                    $("#modalStudyGroup").modal('show')
                } else if (this.verifyResource(additionalResource.url_facebook)) {
                    this.goTo(additionalResource.url_facebook)
                } else if (this.verifyResource(additionalResource.url_telegram)) {
                   this.goTo(additionalResource.url_telegram)
                }
            },
            goTo(url){
                window.open(url, '_blank', 'noopener')
            }
        }
    }
</script>

<style scoped lang="css">
    @import "~selectize/dist/css/selectize.css";
    @import "~selectize/dist/css/selectize.default.css";
    @import '~vue-slider-component/theme/default.css';
</style>

<style scoped lang="scss">
    html.theme-light {
        .bg-disabled {
            background-color: #f8f9fa;
        }
    }

    html.theme-dark {
        .bg-disabled {
            background-color: #1b2227;
        }
    }

    html.theme-light, html.theme-dark {
        .vue-slider.vue-slider-ltr {
            height: 18px!important;
        }

        .pt-6 {
            padding-top: 2.5rem;
        }

        .switch {
            height: 24px;
            width: 50px;
        }

        .slider:before {
            height: 16px;
            width: 16px;
        }

        .nav-tabs-custom .nav-item .nav-link:hover {
            background: #3051d3;
            color: #fff !important;
        }

        .nav-tabs-custom .nav-item .nav-link {
            color: #74797d;
            border-top-left-radius: .25rem;
            border-top-right-radius: 3.95rem;
        }

        .nav-tabs .nav-link.active {
            background: #3051d3 !important;
            color: #fff !important
        }

        .nav-tabs .nav-link.active h6 {
            color: #fff !important
        }

        #label-filters-favorite-checkbox:before {
            border-radius: 0 !important;
        }

        .field-loader {
            border: 5px solid #f3f3f3;
            border-radius: 50%;
            border-top: 5px solid #3051d3;
            width: 28px;
            height: 28px;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;
        }

        .field-loader-sum {
            border: 5px solid #f3f3f3;
            border-radius: 50%;
            border-top: 5px solid #3051d3;
            width: 35px;
            height: 35px;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;
        }

        .descrizione-competition {
            background-color: #e6e6ff;
            padding: 5px 10px;
            border-radius: 10px;
        }

        /* Safari */
        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        @media (max-width: 1279px) {
            .custom-pt-sm-3 {
                padding-top: 1rem !important;
            }
        }
    }
</style>
