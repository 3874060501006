<template>
    <CompetitionDetail :tab="tabToOpen" :idCompetition="id_competition" :key="id_competition" v-if="id_competition !== undefined" v-on:is-components-ready="isComponentsReady"></CompetitionDetail>
</template>

<script>
    import CompetitionDetail from "@/views/CompetitionDetail";
    import {mapGetters} from 'vuex'
    import {MODULES} from "@/store/modules-enum";
    import {COMPETITION_GETTERS} from "@/store/competition/getters-enum";
    import _ from 'lodash'
    import {competitionDetailTabsEnums as tabsEnums, routesEnums} from "@/helpers/enums";

    export default {
        name: "CompetitionDetailWrapper",
        components: {
            CompetitionDetail
        },
        data () {
            return {
                id_competition: undefined,
                tabToOpen: undefined
            }
        },
        computed: {
            ...mapGetters(MODULES.COMPETITION, [COMPETITION_GETTERS.SELECTED_COMPETITIONS, COMPETITION_GETTERS.GET_SELECTED_COMPETITIONS])
        },
        watch: {
            getSelectedCompetition: function (newValue) {
                this.verifyCompetition()
            }
        },
        mounted() {
            this.verifyCompetition()
            this.setQueryParams()
        },
        methods: {
            setQueryParams () {
                if (this.$route.query !== null && !_.isEmpty(this.$route.query) && this.$route.query.type !== null && this.$route.query.type !== "") {
                    this.tabToOpen = this.$route.query.type
                } else {
                    this.tabToOpen = tabsEnums.EXERCISE
                }
            },
            verifyCompetition () {
                if (this.getSelectedCompetition !== undefined) {
                    if (this.getSelectedCompetition.id_concorso !== undefined) {
                        this.id_competition = this.getSelectedCompetition.id_concorso
                    } else {
                        this.$router.push({ name: routesEnums.HOME })
                    }
                } else {
                    this.$router.push({ name: routesEnums.HOME })
                }
            },
            isComponentsReady (label, state) {
                this.$emit('is-components-ready', label, state)
            }
        }
    }
</script>

<style scoped>

</style>